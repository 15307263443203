<template>
    <div>
        <b-card title="Pays des sites" v-show="action=='list'">
            <b-button pill size="sm" class="mb-2" variant="success" @click="createCInsert" >
                <b-icon icon="plus-circle" aria-hidden="true"></b-icon>&nbsp;Ajouter
            </b-button>
            <b-input-group size="sm">
              <b-form-input id="filter-input"
                            v-model="filterValue"
                            placeholder="Nom du Pays" @change="filter = filterValue"></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!filterValue" @click="filter = filterValue">Filtrer</b-button>
                  <b-button :disabled="!filter" @click="filter = ''; filterValue = ''">Clear</b-button>
                </b-input-group-append>
            </b-input-group>
            <b-pagination v-model="currentPage"
                          :total-rows="totalRows"
                          :per-page="perPage"
                          aria-controls="countryCls" :busy="countryInsertLoading"></b-pagination>
            <b-table :items="countryCls" :fields="countriesFields" head-variant="light" sticky-header="600px"
                     :per-page="perPage" :current-page="currentPage" :filter="filter" :filter-included-fields="filterOn"
                     :sort-by.sync="sortBy" tbody-tr-class="trclass" :busy="countryInsertLoading" @filtered="onFiltered">
                <template #cell(name)="data">
                    <span class="text-capitalize">{{ data.value }}</span>
                </template>
                <template  #cell(countriesNames)="data" class="text-capitalize">
                    <span class="text-capitalize">{{data.value}}</span>
                </template>
                <template #cell(id)="data">
                  <b-button size="sm" class="mb-2" >
                    <b-icon icon="gear-fill" aria-hidden="true"  @click="editCInsert(data.value)"></b-icon>
                  </b-button>
                  <b-button size="sm" class="mb-2">
                    <b-icon icon="trash" aria-hidden="true" @click="openConfirmDel(data.item)"></b-icon>
                  </b-button>
                </template>
            </b-table>
        </b-card>
      <b-modal
          id="modal-prevent-delete"
          ref="modal"
          :title="'Confirmer la suppression de ' + deletePays.name"
          @show="resetModal"
          @hidden="resetModal"
          @ok="handleOk"
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <p>Êtes-vous sûr de vouloir supprimer le pays des sites <b>{{ deletePays.name }}</b> ?<br>
          Cela peut avoir un impact sur la récupération des données des sites</p>
          <b-form-group
              invalid-feedback="La confirmation est requise"
              :state="confirmDeleteState"
          >
            <b-form-checkbox
                id="confirm-input"
                v-model="deletedConfirmed"
                :state="confirmDeleteState"
                required>Confirmation de suppression</b-form-checkbox>
          </b-form-group>
        </form>
      </b-modal>
        <EditCountryInsert v-if="action=='edit'" :editId="editId" v-on:reset="resetForm"></EditCountryInsert>
        <CreateCountryInsert v-if="action=='create'" :editId="editId" v-on:reset="resetForm"></CreateCountryInsert>
    </div>
</template>

<script>
    import { mapState } from "vuex"
    import EditCountryInsert from './EditCountryInsert.vue'
    import CreateCountryInsert from './CreateCountryInsert.vue'
    import axios from "axios";

    export default {
        name: 'CountryInsert',
        components: {
          EditCountryInsert,
          CreateCountryInsert
        },
        data() {
            return {
                deletedConfirmed: false,
                confirmDeleteState: null,
                countryCleans: [],
                filterValue: null,
                filter: null,
                sortBy: 'name',
                filterOn: ['name'],
                action: "list",
                editId: 0,
                perPage: 8,
                currentPage: 1,
                countRows: -1,
                deletePays: {name: '', id: -1},
                countriesFields: [
                    {
                        key: 'name',
                        label: 'Pays dans les sites',
                        sortable: true,
                        thStyle: { width: "30%" },
                    },
                    {
                      key: 'countriesNames',
                      label: 'Pays',
                      thStyle: { width: "62%" },
                    },
                    {
                      key: 'id',
                      label: 'Action',
                      thStyle: { width: "8%" },
                    }
                ]
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return ''
                value = value.toString().toLowerCase()

                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            upper: function (value) {
                if (!value) return ''
                return value.toString().toUpperCase()
            },
            ln2br: function (value) {
                if (!value) return ''
                return value.toString().replace("\n", "<br>")
            },
            countryToLine: function (values) {
                if (values.length == 0) return ''
                values.sort((a, b) => {
                    return a.localeCompare(b)
                })
                return values.join(", ")
            },
        },
        props: {
        },
        methods: {
            openConfirmDel(cCountryToDel) {
              this.deletePays = cCountryToDel
              this.$bvModal.show('modal-prevent-delete');
            },
            checkFormValidity() {
              this.confirmDeleteState = this.deletedConfirmed
              return this.deletedConfirmed
            },
            resetModal() {
              this.deletedConfirmed = false
              this.confirmDeleteState = null
            },
            resetForm: function () {
                this.action = 'list'
            },
            createCInsert: function () {
              this.action = 'create'
            },
            editCInsert: function (id) {
                this.editId = id
                this.action = 'edit'
            },
            onFiltered: function(filteredItems) {
              // Trigger pagination to update the number of buttons/pages due to filtering
              if (this.countryInserts == undefined || this.countryInserts == null) {
                this.countRows = -1
                this.currentPage = 1
                return
              }
              this.countRows = filteredItems.length
              this.currentPage = 1
            },
            handleOk(bvModalEvent) {
              // Prevent modal from closing
              bvModalEvent.preventDefault()
              // Trigger submit handler
              this.handleSubmit()
            },
            handleSubmit() {
              // Exit when the form isn't valid
              if (!this.checkFormValidity()) {
                return
              }

              // Hide the modal manually
              this.$nextTick(() => {
                let self = this
                axios.get(this.$ipService + '/api/CountryInserts/delete/' + this.deletePays.id
                ).then(function () {
                  self.$store.dispatch('loadCountryInserts')
                  self.$store.dispatch('loadGroupcountries')
                  self.$store.dispatch('loadCountries')
                  self.$store.dispatch('loadCountriesActives')
                  self.$store.dispatch('loadGrouptypes')
                  self.$store.dispatch('loadGrouplevels')
                  self.$store.dispatch('loadWebsites')
                  self.$store.dispatch('loadGroupdurations')
                  self.$store.dispatch('loadGroupproducts')
                  self.$emit('reset')
                }).catch(function (error) {
                  console.log(error)
                }).finally(() => {
                  self.$bvModal.hide('modal-prevent-delete')
                  self.deletePays = {name: '', id: -1}
                })

              })
            }
        },
        computed: {
            countryCls: function () {
              if (this.countryInserts == undefined || this.countryInserts == null) {
                return []
              }
              let countryCleans = []
              this.countryInserts.forEach((country) => {

                let listCountryName = [];
                if (country?.countryCodes !== undefined) {
                  country.countryCodes.forEach((code) => {
                    let crty = this.countriesByCode[code];
                    if (crty !== undefined) {
                      listCountryName.push(crty.name.toUpperCase());
                    }

                  })
                }
                countryCleans.push({name: country.name, countries: listCountryName, countriesNames: listCountryName.join(', '), id: country.id})
              })
              return countryCleans
            },
            totalRows: function () {
              if (this.countRows < 0) {
                if (this.countryInserts == undefined || this.countryInserts == null) {
                  return 0
                }
                return this.countryInserts.length
              }
              return this.countRows
            },
            ...mapState(["countriesByCode", "countryInserts", "countryInsertLoading"])
        },
        mounted: function () {

        }
    };
</script>

<style scoped>
    .icon-span {
        margin: 5px;
    }
    .clickable {
        cursor: pointer
    }
    .limit-50 {
      display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 600px;
    }
    .thead-light {
      height: 15px;
    }
    tr.trclass {
      height: 15px;
    }

</style>
