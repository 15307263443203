<template>
    <div>
        <b-card title="Level des utilisateurs" v-show="action=='list'">
            <b-button pill size="sm" class="mb-2" variant="success" @click="userCreate">
                <b-icon icon="plus-circle" aria-hidden="true"></b-icon>&nbsp;Ajouter
            </b-button>
            <b-table :items="users" :fields="usersFields" head-variant="light" :busy="loading" sticky-header="700px">
                <template #cell(lastName)="data">
                    {{ data.value }}
                </template>
                <template #cell(firstname)="data">
                    {{ data.value }}
                </template>
                <template #cell(email)="data">
                    {{ data.value }}
                </template>
                <template #cell(type)="data">
                    {{ data.value | typeUser }}
                </template>
                <template #cell(id)="data">
                    <b-button size="sm" class="mb-2" @click="userEdit(data.value)">
                        <b-icon icon="gear-fill" aria-hidden="true"></b-icon>
                    </b-button>
                    <b-button size="sm" class="mb-2" @click="deleteUser(data.value, data.item.lastname + ' ' + data.item.firstname)">
                        <b-icon icon="trash" aria-hidden="true"></b-icon>
                    </b-button>
                </template>
                <template #table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>
            </b-table>
        </b-card>
        <EditUser :editId="editId" v-if="action=='edit'" v-on:reset="resetForm" v-on:updated="loadUsers"></EditUser>
        <CreateUser v-if="action=='create'" v-on:reset="resetForm" v-on:updated="loadUsers"></CreateUser>
    </div>
</template>

<script>
    import Vue from 'vue'
    import axios from 'axios'
    import EditUser from "./EditUser.vue"
    import CreateUser from "./CreateUser.vue"

    export default {
        name: 'ListUsers',
        components: {
            EditUser,
            CreateUser
        },
        data() {
            return {
                action: "list",
                users: [],
                editId: 0,
                loading: false,
                usersFields: [
                    {
                        key: 'lastname',
                        label: 'Nom'
                    },
                    {
                        key: 'firstname',
                        label: 'Prénom'
                    },
                    {
                        key: 'email',
                        label: 'Email'
                    },
                    {
                        key: 'type',
                        label: 'Type'
                    },
                    {
                        key: 'id',
                        label: 'Action'
                    }]
            }
        },
        filters: {
            typeUser: function (value) {
                if (value == "admin") {
                    return "Administrateur"
                }
                return "Utilisateur"
            },
        },
        props: {
        },
        methods: {
            
            deleteUser: function (id, name) {
                let self = this
                if (confirm("voulez-vous vraiment supprimer l'utilisateur " + name + " ? ")) {
                    axios.delete(this.$ipService + '/api/Users/' + id
                    ).then(function () {
                        // do nothing
                    }).catch(function (error) {
                        console.log(error)
                    }).finally(() => {
                        self.loadUsers()
                    })
                }
            },
            userCreate: function () {
                this.action = "create"
            },
            userEdit: function (id) {
                this.editId = id,
                this.action = "edit"
            },
            loadUsers: function () {
                let self = this
                let ipService = Vue.prototype.$ipService
                this.loading = true
                self.users = []
                axios.get(ipService + "/api/users").then(response => {
                    response.data.forEach(user => {
                        if (!user.visible) return
                        self.users.push({ id: user.id, lastname: user.profile.lastName, firstname: user.profile.firstName, email: user.email, type: user.userType.name })
                    })
                }).finally(() => {
                    self.loading = false
                    self.users.sort((a, b) => {
                        let aName = a.lastname + ' ' + a.firstname
                        let bName = b.lastname + ' ' + b.firstname
                        return aName.toUpperCase().localeCompare(bName.toUpperCase())
                    })
                }
                )
            },
            resetForm: function () {
                this.action = 'list'
                this.editId = 0
            }
        },
        computed: {
        },
        mounted: function () {
            this.loadUsers()
        }
    };
</script>

<style scoped>
</style>