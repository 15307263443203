<template>
    <GChart type="LineChart"
            :data="chartData"
            :options="chartOptions" :events="chartEvents" ref="gChart" />
</template>

<script>
    import moment from 'moment'
    import { mapState } from "vuex"
    import { GChart } from 'vue-google-charts'

    var numeral = require('numeral')

    export default {
        name: 'DetailChart',
        components: {
            GChart
        },
        props: {
            products: Array,
            dates: Object,
            detail: String,
            isLoaded: Boolean
        },
        event: ['selectcol', 'selectrow'],
        data() {
            return {
                loaded: 0,
                dateDetails: {},
                mindate: null,
                maxdate: null,
                chartOptions: {
                    hAxis: {
                        format: 'dd/MM/yyyy',
                        slantedText: true
                    },
                    tooltip: { isHtml: true },
                    pointSize: 4,
                    height: 600,
                    width: '100%',
                    interpolateNulls: true,
                    chart: {
                    }
                },
                chartEvents: {
                    select: () => {
                        const table = this.$refs.gChart.chartObject
                        const selection = table.getSelection()
                        let data = this.$refs.gChart.getValidChartData()
                        for (let i = 0; i < selection.length; i++) {
                            let item = selection[i];
                            if (item.column != null) {
                                this.$emit("selectcol", data.getColumnLabel(item.column))
                                if (item.row != null) {
                                    this.$emit("selectrow", data.getColumnLabel(item.column), moment(data.getValue(item.row, 0)).format('YYYY-MM-DD'));
                                }
                            }
                        }
                    },
                }
            }
        },
        computed: {
            chartData: function () {
                if (this.isLoaded && window.google != undefined && this.currentTab == "detail" && this.currentDetail == this.detail ) {
                    let chartData = new window.google.visualization.DataTable()
                    chartData.addColumn('date', 'Time of Day');
                    let finalArray = [];
                    let lineTitle = ['dates'];

                    this.products.forEach(p => {
                        lineTitle.push(p.code)
                        chartData.addColumn('number', p.tabName)
                        chartData.addColumn({ 'type': 'string', 'role': 'style' })
                        chartData.addColumn({ 'type': 'string', 'role': 'tooltip', 'p': { 'html': true } })
                    });
                    let dates = Object.keys(this.dates)
                    dates.sort((a, b) => {
                        let vala = Math.round(moment(a, "YYYY-MM-DD").format('x'))
                        let valb = Math.round(moment(b, "YYYY-MM-DD").format('x'))
                        return vala - valb
                    })


                    dates.forEach(dte => {
                        let details = this.dates[dte]
                        let mDate = moment(dte, "YYYY-MM-DD")
                        let lineValue = [mDate.toDate()]
                        this.products.forEach(p => {
                            if (details[p.code] != undefined) {
                                let detail = details[p.code].d
                                let momentD = moment(detail.updateDate.substr(0, 10), "YYYY-MM-DD")
                                let momentP = moment(p.updateDate.substr(0, 10), "YYYY-MM-DD")
                                let opacity = 1
                                if (momentD.isBefore(momentP) || detail.open == false) {
                                    opacity = 0.3
                                }
                                lineValue.push(detail.value)
                                if (detail.guaranteed) {
                                    if (detail.registrable)
                                        lineValue.push('point { size: 12; shape-type: star; opacity: ' + opacity + ' }')
                                    else
                                        lineValue.push('point { size: 12; shape-type: star; fill-color: #DDDDDD; opacity: ' + opacity + '  }')
                                } else {
                                    if (detail.registrable)
                                        lineValue.push('point { size: 6; shape-type: circle; shape-dent: 0.1; opacity: ' + opacity + ' }')
                                    else
                                        lineValue.push('point { size: 6; shape-type: circle; shape-dent: 0.1; fill-color: #DDDDDD; opacity: ' + opacity + ' }')
                                }
                                let tooltip = "<div class='chartTooltip'><h2>" + p.code + "</h2>";
                                tooltip += "Du : " + mDate.format('DD/MM/YYYY') + "<br>";
                                tooltip += "Prix : " + numeral(detail.value).format('0,0.00 $') + "<br>";
                                tooltip += "Status : " + (detail.realStatus != null?detail.realStatus.replace(" ", "&nbsp;"):"indéfini") + "</div>";
                                lineValue.push(tooltip)
                            } else {
                                lineValue.push(null)
                                lineValue.push(null)
                                lineValue.push(null)
                            }
                        })
                        finalArray.push(lineValue)
                    })

                    chartData.addRows(finalArray)
                    return chartData
                }
                return []
            },
            ...mapState(["currentTab", "currentDetail"])
        },
        mounted: function () {
        }
    }
</script>

<style>
    .chartTooltip {
        min-width: 150px;
        padding: 8px;
        white-space: nowrap;
    }
    .chartTooltip h2 {
        font-size: 1.5em;
        color: #0094ff;
        font-weight: bolder;
    }
</style>