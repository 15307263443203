<template>
    <div class="home">
        <h1>Welcom {{ currentUser.profile.firstName }} {{ currentUser.profile.lastName }}</h1>
        <p>Welcome to your new single-page application, built with <a href="javascript:return false" v-on:click="logout">Logout</a>.</p>
    </div>
</template>

<script>
    import axios from 'axios';
    import Vue from 'vue';
    import { mapState } from "vuex";

    export default {
        name: 'Home',
        data() {
            return {
                
            }
        },
        computed: mapState(["currentUser"]),
        methods: {
            logout: function () {
                let self = this
                axios.post(this.$ipService + '/api/users/logout',
                    {
                        id: Vue.$cookies.get("session_id")
                    }
                ).then(function () {
                    Vue.$cookies.set("session_id", "")
                    self.$store.commit('setUser', null)
                    return
                })
                .catch(function (error) {
                    console.log(error);
                });
                return false;
            }
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

