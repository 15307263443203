<template>
    <div>
        <b-card title="Pays" v-show="action=='list'">
            <b-button pill size="sm" class="mb-2" variant="success">
                <b-icon icon="plus-circle" aria-hidden="true"></b-icon>&nbsp;Ajouter
            </b-button>
            <b-input-group size="sm">
              <b-form-input id="filter-input"
                            v-model="filterValue"
                            placeholder="Nom ou Code du Pays" @change="filter = filterValue"></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filterValue" @click="filter = filterValue">Filtrer</b-button>
                <b-button :disabled="!filter" @click="filter = ''; filterValue = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
            <b-table :items="countriesSort" :fields="countriesFields" head-variant="light" sticky-header="600px"

                     :filter="filter"
                     :filter-included-fields="filterOn"
                     tbody-tr-class="trclass"
            >
              <template #cell(id)="data">
                {{ data.value }}
              </template>
                <template #cell(name)="data">
                  <span class="text-capitalize">{{ data.value }}</span>
                </template>
                <template  #cell(countryInserts)="data">
                    <span class="limit-50 text-capitalize" :id="'span-'+data.item.id" v-b-tooltip.hover.bottom :title="data.value|countryToLine" >{{data.value|countryToLine}}</span>
                </template>
                <template #cell(code)>
                  <b-button size="sm" class="mb-2" >
                    <b-icon icon="gear-fill" aria-hidden="true"></b-icon>
                  </b-button>
                  <b-button size="sm" class="mb-2">
                    <b-icon icon="trash" aria-hidden="true"></b-icon>
                  </b-button>
                </template>
            </b-table>
        </b-card>
        <!-- EditZoneGeo v-if="action=='edit'" :editId="editId" v-on:reset="resetForm"></EditZoneGeo>
        <CreateZoneGeo v-if="action=='create'" :editId="editId" v-on:reset="resetForm"></CreateZoneGeo -->
    </div>
</template>

<script>
    import { mapState } from "vuex"
    //import EditZoneGeo from './EditZoneGeo.vue'
    //import CreateZoneGeo from './CreateZoneGeo.vue'
    //import axios from 'axios'

    export default {
        name: 'Country',
        components: {
            //EditZoneGeo,
            //CreateZoneGeo
        },
        data() {
            return {
                filterValue: null,
                filter: null,
                filterOn: [],
                action: "list",
                editId: 0,
                countriesFields: [
                    {
                      key: 'id',
                      label: 'Code'
                    },
                    {
                        key: 'name',
                        label: 'Pays'
                    },
                    {
                        key: 'countryInserts',
                        label: 'Pays dans les sites'
                    },
                    {
                      key: 'code',
                      label: 'Action'
                    }
                ]
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return ''
                value = value.toString().toLowerCase()

                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            upper: function (value) {
                if (!value) return ''
                return value.toString().toUpperCase()
            },
            ln2br: function (value) {
                if (!value) return ''
                return value.toString().replace("\n", "<br>")
            },
            countryToLine: function (value) {
                if (value.length == 0) return ''
                let arrName = []
                value.forEach((va) => { arrName.push(va.name.toLowerCase()) });
                arrName.sort((a, b) => {
                    return a.localeCompare(b);
                })
                return arrName.join(", ")
            },
        },
        props: {
        },
        methods: {
            resetForm: function () {
                this.action = 'list'
            }
        },
        computed: {
            countriesSort: function () {
                if (this.countries == null) {
                    return []
                }
                let arrCountries = this.countries
                arrCountries.forEach(
                    (country) => {
                      country.name = country.name.toLowerCase();
                      country.code = country.id;
                    }
                )
                arrCountries.sort((a, b) => {
                  return a.name.localeCompare(b.name);
                })
                return arrCountries;
            },
            ...mapState(["webSites", "groupDurations", "countries", "groupCountries", "groupLevels", "groupTypes", "currentDetail", "currentUser"])
        },
        mounted: function () {
        }
    };
</script>

<style scoped>
    .icon-span {
        margin: 5px;
    }
    .clickable {
        cursor: pointer
    }
    .limit-50 {
      display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 600px;
    }
    .thead-light {
      height: 15px;
    }
    tr.trclass {
      height: 15px;
    }
</style>

<style>
  .tooltip-inner {
    max-width: 600px!important;
  }
  .tooltip > .arrow {
    visibility: hidden!important;
    display: none!important;
  }
</style>
