<template>
    <b-card title="Edition des utilisateurs" >
        <b-form title="Information de connexion">
            <b-form-group id="input-group-email"
                          label="Email :"
                          label-for="user-email">
                <b-form-input id="user-email"
                              type="email"
                              placeholder="Email"
                              v-model="postuser.email"
                              required></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-password"
                          label="Password :"
                          label-for="user-password">
                <b-form-input id="user-password"
                              type="password"
                              placeholder="*****"
                              v-model="postuser.password"
                              required></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-type"
                          label="Type :"
                          label-for="input-type">
                <b-form-select id="input-type"
                               :options="typeUserOptions"
                               v-model="postuser.userTypeId"></b-form-select>
            </b-form-group>
            <b-form-group id="input-group-firstname"
                          label="Prénom :"
                          label-for="user-firstname">
                <b-form-input id="user-email"
                              type="text"
                              placeholder="Prénom"
                              v-model="postuser.firstName"
                              required></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-lastname"
                          label="Nom :"
                          label-for="user-lastname">
                <b-form-input id="user-lastname"
                              type="text"
                              placeholder="Nom"
                              v-model="postuser.lastName"
                              required></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-function"
                          label="Fonction :"
                          label-for="user-function">
                <b-form-input id="user-function"
                              type="text"
                              placeholder="Fonction"
                              v-model="postuser.function"
                              required></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-role"
                          label="Role :"
                          label-for="user-role">
                <b-form-input id="user-role"
                              type="text"
                              placeholder="Role"
                              v-model="postuser.role"
                              required></b-form-input>
            </b-form-group>

            <b-button type="button" variant="primary" @click="postform">Submit</b-button>
            <b-button type="button" variant="warning" @click="resetform">Retour</b-button>
        </b-form>
    </b-card>
</template>

<script>
    import { mapState } from "vuex"
    import axios from 'axios'


    export default {
        name: 'CreateUser',
        components: {
        },
        data() {
            return {
                postuser: {},
                typeUserOptions: [
                    { value: "1", text: "Administrateur" },
                    { value: "2", text: "Utilisateur" }
                ],
            }
        },
        filters: {
        },
        event: ['reset', 'save'],
        methods: {
            resetform: function () {
                this.$emit('reset')
            },
            postform: function () {
                let self = this
                axios.post(this.$ipService + '/api/Users/', this.postuser
                ).then(function () {
                }).catch(function (error) {
                    console.log(error)
                }).finally(() => {
                    self.$emit('updated')
                    self.$emit('reset')
                })

                
            },
        },
        computed: {
            ...mapState(["currentUser"])
        },
        mounted: function () {
            this.postuser = {
                id: null,
                email: null,
                password: null,
                userTypeId: 2,
                profileId: null,
                lastName: null,
                firstName: null,
                civility: null,
                birthDate: null,
                adress1: null,
                adress2: null,
                zipCode: null,
                city: null,
                province: null,
                country: null,
                status: null,
                function: null,
                role: null
            }            
        }
    };
</script>

<style scoped>
    .icon-span {
        margin: 5px;
    }

    .clickable {
        cursor: pointer
    }
</style>
