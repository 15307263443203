<template>
    <div>
        <b-card title="Éditer les produits" v-if="product == null">
            <div class="list-form">
                <b-form inline>
                    <label class="mr-sm-2" for="website-select">Choix du site</label>
                    <b-form-select id="website-select"
                                   class="mb-2 mr-sm-2 mb-sm-0"
                                   :options="listwebsite" v-model="adapter" @change="loadProducts()"></b-form-select>
                </b-form>
                <b-input-group size="sm">
                    <b-form-input v-model="filterValue"
                                  placeholder="Code produit / Type / Niveau /Pays"></b-form-input>

                    <b-input-group-append>
                        <b-button :disabled="!filterValue" @click="filter = filterValue">Filtrer</b-button>
                        <b-button :disabled="!filter" @click="filter = ''; filterValue = ''">Clear</b-button>
                    </b-input-group-append>
                </b-input-group>
            </div>
            <div>
                <b-table :sticky-header="arraySize" :busy="loading" :fields="fields" :items="listproducts" head-variant="light"
                         :filter="filter"
                         :filter-included-fields="filterOn" responsive>
                    <template #cell(code)="data">
                        <span @click="editProduct(data.item.id)" class="pointer">{{data.value}}</span>
                    </template>
                    <template #cell(creationDate)="data">
                        {{data.value | moment('DD/MM/YYYY')}}
                    </template>
                    <template #cell(updatedDate)="data">
                        {{data.value | moment('DD/MM/YYYY')}}
                    </template>
                    <template #cell(editedDate)="data">
                        <span v-if="data.value != null && data.value != ''">{{data.value | moment('DD/MM/YYYY')}}</span>
                    </template>
                    <template #table-busy>
                        <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                        </div>
                    </template>
                </b-table>
            </div>
        </b-card>
        <b-card title="Edition d'un Produit" v-if="product != null">
            <b-form v-show="!load_product">
                <b-form-group label="Code" label-for="edit-product-code" label-cols-sm="3" label-align-sm="right">
                    <b-form-input id="edit-product-code"
                                    v-model="product.code"
                                    type="text"></b-form-input>
                </b-form-group>
                <b-form-group label="Titre" label-for="edit-product-title" label-cols-sm="3" label-align-sm="right">
                    <b-form-input id="edit-product-title"
                                    v-model="product.title"
                                    type="text"></b-form-input>
                </b-form-group>
                <b-form-group label="Durée" label-for="edit-product-duration" label-cols-sm="3" label-align-sm="right">
                    <b-form-input id="edit-product-duration"
                                    v-model="product.duration"
                                    type="number"></b-form-input>
                </b-form-group>
                <b-form-group label="Date de création" label-for="edit-product-creationDate" label-cols-sm="3" label-align-sm="right">
                    <b-form-datepicker id="edit-product-creationDate" v-model="product.creationDate" disabled="disabled" class="mb-2"></b-form-datepicker>
                </b-form-group>
                <b-form-group label="Mise à jour" label-for="edit-product-updateDate" label-cols-sm="3" label-align-sm="right">
                    <b-form-datepicker id="edit-product-updateDate" v-model="product.updateDate" disabled="disabled" class="mb-2"></b-form-datepicker>
                </b-form-group>
                <b-form-group label="Activer" label-for="edit-product-activ" label-cols-sm="3" label-align-sm="right">
                    <b-form-checkbox id="edit-product-activ"
                                        v-model="product.activ"></b-form-checkbox>
                </b-form-group>
                <b-form-group label="Niveau du site" label-for="edit-product-leveldebug" label-cols-sm="3" label-align-sm="right">
                    <b-form-input id="edit-product-leveldebug"
                                    v-model="product.levelDebug"
                                    type="text" disabled="disabled"></b-form-input>
                </b-form-group>
                <b-form-group label="Niveau" label-for="edit-product-levelId" label-cols-sm="3" label-align-sm="right">
                    <b-form-select id="edit-product-levelId" v-model="product.levelId" :options="levelsSelect"></b-form-select>
                </b-form-group>
                <b-form-group label="Types du site" label-for="edit-product-typedebug" label-cols-sm="3" label-align-sm="right">
                    <b-form-input id="edit-product-typedebug"
                                    v-model="product.typesDebug"
                                    type="text" disabled="disabled"></b-form-input>
                </b-form-group>
                <b-form-group label="Types" label-for="edit-product-types" label-cols-sm="3" label-align-sm="right">
                    <b-form-checkbox-group id="edit-product-types" class="typeslist" v-model="productTypes" :options="productsTypeSelect" stacked></b-form-checkbox-group>
                </b-form-group>
                <b-form-group label="Pays du site" label-for="edit-product-countriesdebug" label-cols-sm="3" label-align-sm="right">
                    <b-form-input id="edit-product-countriesdebug"
                                    v-model="product.countriesDebug"
                                    type="text" disabled="disabled"></b-form-input>
                </b-form-group>
                <b-form-group label="Pays" label-for="edit-product-countries" label-cols-sm="3" label-align-sm="right">
                    <b-form-checkbox-group id="edit-product-countries" class="typeslist" v-model="productCountries" :options="countriesSelect" stacked></b-form-checkbox-group>
                </b-form-group>
                <b-button @click="cancelEdit()" variant="danger">Annuler</b-button>
                <b-button @click="saveProduct()" variant="primary">Sauvegarder</b-button>
            </b-form>
            <div v-show="load_product" class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
            </div>
        </b-card>
    </div>
</template>

<script>

    import Vue from 'vue'
    import { mapState } from "vuex"
    import axios from 'axios'
    import { BJumbotron } from 'bootstrap-vue'
    Vue.component('b-jumbotron', BJumbotron)

    export default {
        name: 'ListAllProducts',
        components: {
        },
        data() {
            return {
                load_product: false,
                loading: false,
                product: null,
                productTypes: [],
                productCountries: [],
                filterValue: "",
                filter: "",
                levels: [],
                types: [],
                listproducts: [],
                filterOn: [],
                listwebsite: [{ title: "choisir un site", value: 0}],
                adapter: 0,
                fields: [
                    {
                        key: 'code', label: 'Code'
                    },
                    {
                        key: 'duration', label: 'Durée'
                    },
                    {
                        key: 'creationDate', label: 'Création'
                    },
                    {
                        key: 'updatedDate', label: 'Mise à jour'
                    },
                    {
                        key: 'editedDate', label: 'Modifié le'
                    },
                    {
                        key: 'minTravelers', label: 'Min pax'
                    },
                    {
                        key: 'level', label: 'Niveau'
                    },
                    {
                        key: 'types', label: 'Types'
                    },
                    {
                        key: 'countries', label: 'Pays'
                    },
                    {
                        key: 'urlPage', label: 'Url'
                    },
                    {
                        key: 'countriesDebug', label: 'Pays du site'
                    }
                ]
            }
        },
        filters: {
        },
        computed: {
            arraySize: function () {
                return "" + (this.windowsSize - 300) + "px";
            },
            levelsSelect: function () {
                if (this.product == null) {
                    return []
                }
                let levelsSelect = []
                this.levels.forEach(level => {
                    if (level.active || level.id == this.product.levelId) {
                        levelsSelect.push({ value: level.id, text: level.label })
                    }
                })
                return levelsSelect
            },
            countriesSelect: function () {
                if (this.product == null) {
                    return []
                }
                let countriesSelect = []
                this.countries.forEach(country => {
                    countriesSelect.push({ value: country.id, text: country.name })
                })
                countriesSelect.sort((a, b) => {
                    return a.text.toUpperCase().localeCompare(b.text.toUpperCase())
                })
                return countriesSelect
            },
            productsTypeSelect: function () {
                if (this.product == null) {
                    return []
                }
                let productsTypeSelect = []
                this.types.forEach(typeprod => {
                    if (typeprod.active || this.productTypes.indexOf(typeprod.id) >= 0) {
                        productsTypeSelect.push({ value: typeprod.id, text: typeprod.label })
                    }
                })
                productsTypeSelect.sort((a, b) => {
                    return a.text.toUpperCase().localeCompare(b.text.toUpperCase())
                })
                return productsTypeSelect
            },
            ...mapState(["webSites", "windowsSize", "countries"])
        },
        props: {
        },
        methods: {
            saveProduct() {
                if (this.product == null) {
                    return
                }
                let BreakException = {};
                this.product.level = null
                this.product.types = []
                this.product.countries = []
                try {
                    this.levels.forEach(level => {
                        if (level.id == this.product.levelId) {
                            this.product.level = level
                            throw BreakException
                        }
                    })
                } catch (e) {
                    if (e !== BreakException) throw e
                }
                this.types.forEach(prodtype => {
                    if (this.productTypes.indexOf(prodtype.id) >= 0) {
                        this.product.types.push(prodtype)
                    }
                });
                this.countries.forEach(country => {
                    if (this.productCountries.indexOf(country.id) >= 0) {
                        this.product.countries.push(country)
                    }
                });
                let self = this
                axios.put(self.$ipService + '/api/products/' + this.product.id, this.product).then(() => {
                }).catch(error => { console.log(error) }).finally(() => {
                    self.cancelEdit()
                    self.loadProducts()
                })
            },
            cancelEdit() {
                this.product = null
                this.productTypes = []
                this.productCountries = []
            },
            editProduct(id) {
                this.load_product = true
                let self = this
                axios.get(self.$ipService + '/api/products/' + id
                ).then(function (response) {
                    self.product = response.data
                    self.product.types.forEach(prodType => {
                        self.productTypes.push(prodType.id)
                    })
                    self.product.countries.forEach(country => {
                        self.productCountries.push(country.id);
                    })
                }).catch(function (error) {
                    console.log(error);
                }).finally(() => {
                    self.load_product = false
                })
            },
            loadProducts() {
                this.loading = true
                let self = this
                axios.get(self.$ipService + '/api/products/allproducts/' + this.adapter
                ).then(function (response) {
                    self.listproducts = response.data
                }).catch(function (error) {
                    console.log(error);
                }).finally(() => { self.loadLevels(self) })
            },
            loadLevels(el) {
                el.levels = []
                el.loading = true
                let self = el
                axios.get(self.$ipService + '/api/levels/adapter/' + el.adapter
                ).then(function (response) {
                    self.levels = response.data
                }).catch(function (error) {
                    console.log(error);
                }).finally(() => { self.loadTypes(el) })
            },
            loadTypes(el) {
                el.types = []
                el.loading = true
                let self = el
                axios.get(self.$ipService + '/api/producttypes/adapter/' + el.adapter
                ).then(function (response) {
                    self.types = response.data
                }).catch(function (error) {
                    console.log(error);
                }).finally(() => { self.loading = false })
            }
        },
        mounted: function () {
            this.listwebsite = [{ text: "choisir un site", value: 0 }]
            this.webSites.forEach(website => {
                if (website.enabled) {
                  this.listwebsite.push({text: website.name, value: website.adapterId});
                }
            })

        }
    };
</script>

<style scoped>

    .list-form {
        background-color: #e9ecef;
        padding: 5px;
    }
    .pointer {
        cursor: pointer;
    }
    .pointer:hover {
        text-decoration: underline;
    }
    .typeslist {
        height: 200px;
        overflow-y: scroll;
        border: 1px solid #e9ecef;
    }
</style>