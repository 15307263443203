<template>
    <div>
        <b-card title="Level de produit" v-show="action=='list'">
            <b-button pill size="sm" class="mb-2" variant="success" @click="createGLevel">
                <b-icon icon="plus-circle" aria-hidden="true"></b-icon>&nbsp;Ajouter
            </b-button>
            <b-table :items="gLevelsSort" :fields="gLevelsFields" head-variant="light">
                <template #cell(label)="data">
                    {{ data.value }}
                </template>
                <template #cell(webSites)="data">
                    <div v-for="website in data.value" :key="website.id"  v-show="website.enabled">{{website.name|upper}} : {{website.levelproducts|producLevelToLine}}</div>
                </template>
                <template #cell(id)="data">
                    <b-button size="sm" class="mb-2" @click="editGLevel(data.value)">
                        <b-icon icon="gear-fill" aria-hidden="true"></b-icon>
                    </b-button>
                    <b-button size="sm" class="mb-2" @click="deleteGLevel(data.value, data.item.label)">
                        <b-icon icon="trash" aria-hidden="true"></b-icon>
                    </b-button>
                </template>
            </b-table>
        </b-card>
        <EditLevelProduct v-if="action=='edit'" :editId="editId" v-on:reset="resetForm"></EditLevelProduct>
        <CreateLevelProduct v-if="action=='create'" :editId="editId" v-on:reset="resetForm"></CreateLevelProduct>
    </div>
</template>

<script>
    import { mapState } from "vuex"
    import EditLevelProduct from './EditLevelProduct.vue'
    import CreateLevelProduct from './CreateLevelProduct.vue'
    import axios from 'axios'

    export default {
        name: 'LevelProduct',
        components: {
            EditLevelProduct,
            CreateLevelProduct
        },
        data() {
            return {
                action: "list",
                editId: 0,
                gLevelsFields: [
                    {
                        key: 'label',
                        label: 'Level Virtuel'
                    },
                    {
                        key: 'webSites',
                        label: 'Niveaux associés'
                    },
                    {
                        key: 'id',
                        label: 'Action'
                    }]
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return ''
                value = value.toString().toLowerCase()

                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            upper: function (value) {
                if (!value) return ''
                return value.toString().toUpperCase()
            },
            ln2br: function (value) {
                if (!value) return ''
                return value.toString().replace("\n", "<br>")
            },
            producLevelToLine: function (value) {
                if (value.length == 0) return ''
                let arrName = []
                value.forEach(vA => { if (vA.active) arrName.push(vA.label) });
                arrName.sort((a, b) => {
                    return a.localeCompare(b);
                })
                return arrName.join(", ")
            },
        },
        props: {
        },
        methods: {
            deleteGLevel: function (id, name) {
                let self = this
                if (confirm("voulez-vous vraiment supprimer le type " + name + " ? ")) {
                    axios.get(this.$ipService + '/api/GroupLevels/delete/' + id
                    ).then(function () {
                        self.$store.dispatch('loadGroupcountries')
                        self.$store.dispatch('loadCountries')
                        self.$store.dispatch('loadCountriesActives')
                        self.$store.dispatch('loadGrouptypes')
                        self.$store.dispatch('loadGrouplevels')
                        self.$store.dispatch('loadWebsites')
                        self.$store.dispatch('loadGroupdurations')
                        self.$store.dispatch('loadGroupproducts')
                    }).catch(function (error) {
                        console.log(error)
                    }).finally(() => {

                    })
                }
            },
            editGLevel: function (id) {
                this.editId = id
                this.action = 'edit'
            },
            createGLevel: function () {
                this.action = 'create'
            },
            resetForm: function () {
                this.action = 'list'
            }
        },
        computed: {
            gLevelsSort: function () {
                if (this.groupLevels == null) {
                    return []
                }

                let arrGLevels = this.groupLevels
                arrGLevels.sort((a, b) => {
                    return a.label.localeCompare(b.label);
                })
                arrGLevels.forEach(glevel => {
                    glevel.webSites = {}
                    let webSites = {}
                    this.webSites.forEach(website => {
                        webSites[website.adapterId] = { id: website.adapterId, name: website.name, enabled: website.enabled, levelproducts: []}
                    })
                    glevel.levels.sort((a, b) => {
                        return a.label.localeCompare(b.label);
                    })
                    glevel.levels.forEach(productLevel => {
                        webSites[productLevel.adapterId].levelproducts.push(productLevel)
                    })
                    glevel.webSites = Object.values(webSites)

                })


                return arrGLevels;
            },
            ...mapState(["webSites", "groupDurations", "countries", "groupCountries", "groupLevels", "groupTypes", "currentDetail", "currentUser"])
        },
        mounted: function () {
        }
    };
</script>

<style scoped>
    .icon-span {
        margin: 5px;
    }
    .clickable {
        cursor: pointer
    }
</style>
