<template>
    <div>
        <b-card title="Produits modifiés" v-if="product == null">
            <div class="list-form">
                <b-form inline>
                    <label class="mr-sm-2" for="website-select">Choix du site</label>
                    <b-form-select id="website-select"
                                   class="mb-2 mr-sm-2 mb-sm-0"
                                   :options="listwebsite" v-model="adapter" @change="loadProducts()"></b-form-select>
                </b-form>
            </div>
            <div>
                <b-table :sticky-header="arraySize" :busy="loading" :fields="fields" :items="listproducts" head-variant="light" responsive>
                    <template #cell(code)="data">
                        <span @click="editProduct(data.item.id)" class="pointer">{{data.value}}</span>
                    </template>
                    <template #cell(creationDate)="data">
                        <span>{{data.value | moment('DD/MM/YYYY')}}</span>
                    </template>
                    <template #cell(updatedDate)="data">
                        {{data.value | moment('DD/MM/YYYY')}}
                    </template>
                    <template #cell(editedDate)="data">
                        <span v-if="data.value != null && data.value != ''">{{data.value | moment('DD/MM/YYYY')}}</span>
                    </template>
                    <template #cell(id)="data">
                        <b-form-checkbox @change="upgradeProduct(data.value)"
                                         :value="data.value" v-model="listactiv" />
                    </template>
                    <template #cell(idignore)="data">
                        <b-form-checkbox @change="ignoreProduct(data.value)"
                                         :value="data.value" v-model="listignore" />
                    </template>
                    <template #table-busy>
                        <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                        </div>
                    </template>
                </b-table>
                <div v-show="listproducts.length > 0">
                    <b-button @click="validate()" style="float: right; margin: 5px">Valider</b-button>
                    <b-button @click="ignoreAll()" style="float: right; margin: 5px">Tout ignorer</b-button>
                    <b-button @click="checkAll()" style="float: right; margin: 5px">Tout activer</b-button>
                </div>
            </div>
        </b-card>
        <b-card title="Produit modifié" v-if="product != null">
            <b-form v-show="!load_product">
                <b-form-group label="Code" label-for="edited-product-code" label-cols-sm="3" label-align-sm="right">
                    <b-form-input id="edited-product-code"
                                  v-model="product.code"
                                  type="text"></b-form-input>
                </b-form-group>
                <b-form-group label="Titre" label-for="edited-product-title" label-cols-sm="3" label-align-sm="right">
                    <b-form-input id="edited-product-title"
                                  v-model="product.title"
                                  type="text"></b-form-input>
                </b-form-group>
                <b-form-group label="Durée" label-for="edited-product-duration" label-cols-sm="3" label-align-sm="right">
                    <b-form-input id="edited-product-duration"
                                  v-model="product.duration"
                                  type="number"></b-form-input>
                </b-form-group>
                <b-form-group label="Date de création" label-for="edited-product-creationDate" label-cols-sm="3" label-align-sm="right">
                    <b-form-datepicker id="edited-product-creationDate" v-model="product.creationDate" disabled="disabled" class="mb-2"></b-form-datepicker>
                </b-form-group>
                <b-form-group label="Mise à jour" label-for="edited-product-updateDate" label-cols-sm="3" label-align-sm="right">
                    <b-form-datepicker id="edited-product-updateDate" v-model="product.updateDate" disabled="disabled" class="mb-2"></b-form-datepicker>
                </b-form-group>
                <b-form-group label="Activer" label-for="edited-product-activ" label-cols-sm="3" label-align-sm="right">
                    <b-form-checkbox id="edited-product-activ"
                                     v-model="product.activ"></b-form-checkbox>
                </b-form-group>
                <b-form-group label="Niveau du site" label-for="edited-product-leveldebug" label-cols-sm="3" label-align-sm="right">
                    <b-form-input id="edited-product-leveldebug"
                                  v-model="product.levelDebug"
                                  type="text" disabled="disabled"></b-form-input>
                </b-form-group>
                <b-form-group label="Niveau" label-for="edited-product-levelId" label-cols-sm="3" label-align-sm="right">
                    <b-form-select id="edited-product-levelId" v-model="product.levelId" :options="levelsSelect"></b-form-select>
                    <b-form-text id="input-live-help">Ancienne valeur : {{productlog.level.label}} ({{productlog.levelDebug}})</b-form-text>
                </b-form-group>
                <b-form-group label="Types du site" label-for="edited-product-typedebug" label-cols-sm="3" label-align-sm="right">
                    <b-form-input id="edited-product-typedebug"
                                  v-model="product.typesDebug"
                                  type="text" disabled="disabled"></b-form-input>
                </b-form-group>
                <b-form-group label="Types" label-for="edited-product-types" label-cols-sm="3" label-align-sm="right">
                    <b-form-checkbox-group id="edited-product-types" class="typeslist" v-model="productTypes" :options="productsTypeSelect" stacked></b-form-checkbox-group>
                </b-form-group>
                <b-form-group label="Pays du site" label-for="edited-product-countriesdebug" label-cols-sm="3" label-align-sm="right">
                    <b-form-input id="edited-product-countriesdebug"
                                  v-model="product.countriesDebug"
                                  type="text" disabled="disabled"></b-form-input>
                    <b-form-text id="input-live-help">Ancienne valeur : {{productlog.strTypes}} ({{productlog.typesDebug}})</b-form-text>
                </b-form-group>
                <b-form-group label="Pays" label-for="edited-product-countries" label-cols-sm="3" label-align-sm="right">
                    <b-form-checkbox-group id="edited-product-countries" class="typeslist" v-model="productCountries" :options="countriesSelect" stacked></b-form-checkbox-group>
                    <b-form-text id="input-live-help">Ancienne valeur : {{productlog.strCountries}} ({{productlog.countriesDebug}})</b-form-text>
                </b-form-group>
                <b-button @click="cancelEdit()" variant="danger">Annuler</b-button>
                <b-button @click="saveProduct()" variant="primary">Sauvegarder</b-button>
            </b-form>
            <div v-show="load_product" class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
            </div>
        </b-card>
    </div>
</template>

<script>

    import Vue from 'vue'
    import { mapState } from "vuex"
    import axios from 'axios'
    import { BJumbotron } from 'bootstrap-vue'
    Vue.component('b-jumbotron', BJumbotron)

    export default {
        name: 'ListUpdatedProducts',
        components: {
        },
        data() {
            return {
                load_product: false,
                loading: false,
                product: null,
                productlog: null,
                productTypes: [],
                productCountries: [],
                listproducts: [],
                listactiv: [],
                listignore: [],
                levels: [],
                types: [],
                listwebsite: [{ title: "choisir un site", value: 0}],
                adapter: 0,
                fields: [
                    {
                        key: 'code', label: 'Code'
                    },
                    {
                        key: 'duration', label: 'Durée'
                    },
                    {
                        key: 'creationDate', label: 'Création'
                    },
                    {
                        key: 'updatedDate', label: 'Mise à jour'
                    },
                    {
                        key: 'editedDate', label: 'Modifié le'
                    },
                    {
                        key: 'minTravelers', label: 'Min pax'
                    },
                    {
                        key: 'level', label: 'Niveau'
                    },
                    {
                        key: 'types', label: 'Types'
                    },
                    {
                        key: 'countries', label: 'Pays'
                    },
                    {
                        key: 'urlPage', label: 'Url'
                    },
                    {
                        key: 'countriesDebug', label: 'Pays du site'
                    },
                    {
                        key: 'id', label: 'Activer'
                    },
                    {
                        key: 'idignore', label: 'Ignorer'
                    }
                ]
            }
        },
        filters: {
        },
        computed: {
            arraySize: function () {
                return "" + (this.windowsSize - 300) + "px";
            },
            levelsSelect: function () {
                if (this.product == null) {
                    return []
                }
                let levelsSelect = []
                this.levels.forEach(level => {
                    if (level.active || level.id == this.product.levelId) {
                        levelsSelect.push({ value: level.id, text: level.label })
                    }
                })
                return levelsSelect
            },
            countriesSelect: function () {
                if (this.product == null) {
                    return []
                }
                let countriesSelect = []
                this.countries.forEach(country => {
                    countriesSelect.push({ value: country.id, text: country.name })
                })
                countriesSelect.sort((a, b) => {
                    return a.text.toUpperCase().localeCompare(b.text.toUpperCase())
                })
                return countriesSelect
            },
            productsTypeSelect: function () {
                if (this.product == null) {
                    return []
                }
                let productsTypeSelect = []
                this.types.forEach(typeprod => {
                    if (typeprod.active || this.productTypes.indexOf(typeprod.id) >= 0) {
                        productsTypeSelect.push({ value: typeprod.id, text: typeprod.label })
                    }
                })
                productsTypeSelect.sort((a, b) => {
                    return a.text.toUpperCase().localeCompare(b.text.toUpperCase())
                })
                return productsTypeSelect
            },
            ...mapState(["webSites", "windowsSize", "countries"])
        },
        props: {
        },
        methods: {
            upgradeProduct(id) {
                let listIgnore = []
                this.listignore.forEach(x => {
                    if (x == id)
                        return
                    listIgnore.push(x)

                })
                this.listignore = listIgnore
                this.listactiv.push(id)
            },

            validateActive(id, el) {
                el.load_product = true
                el.loading = true
                axios.get(el.$ipService + '/api/products/upgrade/' + id
                ).then(() => {
                    let listproducts = []
                    el.listproducts.forEach(product => {
                        if (product.id == id) {
                            return
                        }
                        listproducts.push(product)
                    })
                    el.listproducts = listproducts
                }).catch(function (error) {
                    console.log(error);
                }).finally(() => {
                    if (el.listactiv.length > 0) {
                        el.validateActive(el.listactiv.pop(), el);
                    } else if (el.listignore.length > 0) {
                        el.validateIgnore(el.listignore.pop(), el);
                    } else {
                        el.load_product = false
                        el.loading = false
                    }
                })
            },
            ignoreProduct(id) {
                let listActiv = []
                this.listactiv.forEach(x => {
                    if (x == id)
                        return
                    listActiv.push(x)

                })
                this.listactiv = listActiv
                this.listignore.push(id)
            },
            validate() {
                if (this.listactiv.length > 0) {
                    this.validateActive(this.listactiv.pop(), this);
                } else if (this.listignore.length > 0) {
                    this.validateIgnore(this.listignore.pop(), this);
                } 
            },
            validateIgnore(id, el) {
                el.load_product = true
                el.loading = true
                axios.get(el.$ipService + '/api/products/ignore/' + id
                ).then(() => {
                    let listproducts = []
                    el.listproducts.forEach(product => {
                        if (product.id == id) {
                            return
                        }
                        listproducts.push(product)
                    })
                    el.listproducts = listproducts
                }).catch(function (error) {
                    console.log(error);
                }).finally(() => {
                    if (el.listactiv.length > 0) {
                        el.validateActive(el.listactiv.pop(), el);
                    } else if (el.listignore.length > 0) {
                        el.validateIgnore(el.listignore.pop(), el);
                    } else {
                        el.load_product = false
                        el.loading = false
                    }
                })
            },
            saveProduct() {
                if (this.product == null) {
                    return
                }
                let BreakException = {};
                this.product.level = null
                this.product.types = []
                this.product.countries = []
                try {
                    this.levels.forEach(level => {
                        if (level.id == this.product.levelId) {
                            this.product.level = level
                            throw BreakException
                        }
                    })
                } catch (e) {
                    if (e !== BreakException) throw e
                }
                this.types.forEach(prodtype => {
                    if (this.productTypes.indexOf(prodtype.id) >= 0) {
                        this.product.types.push(prodtype)
                    }
                });
                this.countries.forEach(country => {
                    if (this.productCountries.indexOf(country.id) >= 0) {
                        this.product.countries.push(country)
                    }
                });
                let self = this
                axios.put(self.$ipService + '/api/products/' + this.product.id, this.product).then(() => {
                }).catch(error => { console.log(error) }).finally(() => {
                    self.cancelEdit()
                    self.loadProducts()
                })
            },
            cancelEdit() {
                this.product = null
                this.productTypes = []
                this.productCountries = []
            },
            editProduct(id) {
                this.load_product = true
                let self = this
                axios.get(self.$ipService + '/api/products/' + id
                ).then(function (response) {
                    let product = response.data
                    let productLogId = 0


                    product.logs.forEach(productLog => {
                        productLogId = Math.max(productLogId, productLog.id)
                        if (productLogId == productLog.id) {
                            self.productlog = productLog
                        }
                    })
                    if (self.productlog != "") {
                        self.productlog.strTypes = ""
                        let arrTypes = []
                        let tbTypes = self.productlog.typesIds.split(";")
                        tbTypes.forEach(tbType => {
                            let dataSplit = tbType.split(':')
                            arrTypes.push(dataSplit[1])
                        })
                        self.productlog.strTypes = arrTypes.join(", ")
                        self.productlog.strCountries = ""
                        let arrCtry = []
                        let tbCountries = self.productlog.countriesIds.split(";")
                        tbCountries.forEach(tbCountry => {
                            let dataSplit = tbCountry.split(':')
                            arrCtry.push(dataSplit[1])
                        })
                        self.productlog.strCountries = arrCtry.join(", ")
                        self.levels.forEach(level => {
                            if (level.id == self.productlog.levelId) {
                                self.productlog.level = level
                            }
                        })
                    }
                    product.types.forEach(prodType => {
                        self.productTypes.push(prodType.id)
                    })
                    product.countries.forEach(country => {
                        self.productCountries.push(country.id);
                    })

                    self.product = response.data

                }).catch(function (error) {
                    console.log(error);
                }).finally(() => {
                    self.load_product = false
                })
            },
            checkAll() {
                this.listactiv = []
                this.listignore = []
                this.listproducts.forEach(x => {
                    this.listactiv.push(x.id)
                })
            },
            ignoreAll() {
                this.listactiv = []
                this.listignore = []
                this.listproducts.forEach(x => {
                    this.listignore.push(x.id)
                })
            },
            uncheckAll() {
                this.listactiv = []
                this.listignore = []
            },
            loadProducts() {
                this.listproducts = []
                this.listactiv = []
                this.listignore = []
                this.loading = true
                let self = this
                axios.get(self.$ipService + '/api/products/updatedproducts/' + this.adapter
                ).then(function (response) {
                    response.data.forEach(product => {
                        product.idignore = product.id
                        self.listproducts.push(product)
                    })
                }).catch(function (error) {
                    console.log(error);
                }).finally(() => { self.loadLevels(self) })
            },
            loadLevels(el) {
                el.levels = []
                el.loading = true
                let self = el
                axios.get(self.$ipService + '/api/levels/adapter/' + el.adapter
                ).then(function (response) {
                    self.levels = response.data
                }).catch(function (error) {
                    console.log(error);
                }).finally(() => { self.loadTypes(el) })
            },
            loadTypes(el) {
                el.types = []
                el.loading = true
                let self = el
                axios.get(self.$ipService + '/api/producttypes/adapter/' + el.adapter
                ).then(function (response) {
                    self.types = response.data
                }).catch(function (error) {
                    console.log(error);
                }).finally(() => { self.loading = false })
            }
        },
        mounted: function () {
            this.listwebsite = [{ text: "choisir un site", value: 0 }]
            this.webSites.forEach(website => {
              if (website.enabled) {
                this.listwebsite.push({text: website.name, value: website.adapterId});
              }
            })
            this.listwebsite.sort((x, y) => {
                return x.text.localeCompare(y.text);
            })
        }
    };
</script>

<style scoped>

    .list-form {
        background-color: #e9ecef;
        padding: 5px;
    }
    .pointer {
        cursor: pointer;
    }
    .pointer:hover {
        text-decoration: underline;
    }

    .typeslist {
        height: 200px;
        overflow-y: scroll;
        border: 1px solid #e9ecef;
    }

    .logproduct[readonly] {
        background-color : #FFF;
        border: none;
    }
</style>