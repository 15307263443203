<template>
    <div class="calview">
        <div style="width: 100%">
            Légende :<br />
            <span class="minvalue">- Inférieur à la concurrence</span>&nbsp;<span class="notminvalue">- Supérieur à la concurrence</span>&nbsp;<span class="closeddate1">- N'est plus en ligne</span>&nbsp;<span class="boldvalue">- Prix le plus bas</span>&nbsp;<span class="closevalue">- N'est plus réservable</span>
        </div>
        <div style="width: 100%; height: 50px;">
            <div style="float:left;margin: 5px; padding: 10px;"><span style="margin:-10px;margin-top: 5px; float:left;background-color:#0094ff;width:10px;height:10px;"></span>Garanti</div>
            <div style="float:left;margin: 5px; padding: 10px;"><span style="margin:-10px;margin-top: 5px; float:left;background-color:#e9e540;width:10px;height:10px;"></span>Promo</div>
            <div style="float:left;margin: 5px; padding: 10px;"><span style="margin:-10px;margin-top: 5px; float:left;background-color:#4cff00;width:10px;height:10px;"></span>Promo & Garanti</div>
            <div style="float:left;margin: 5px; padding: 10px;"><span style="margin:-10px;margin-top: 5px; float:left;background-color:#DDD;width:10px;height:10px;border: 2px solid red;"></span>Supplément</div>
        </div>
        <table >
            <thead>
                <tr>
                    <th style="width:120px; text-align: center">
                        Semaine du
                    </th>
                    <th style="text-align: center">
                        Prix Moyen
                    </th>
                    <template v-for="(product, indexp) in products">
                        <th :key="indexp" style="text-align: center; width:100px;">
                            {{product.webSite.shortname}}-{{product.code}}
                        </th>
                    </template>
                </tr>
            </thead>
            <tbody>
                <template v-for="(val, key) in weekDates">
                    <tr :key="key">
                        <td class="date">
                            {{key|dayOfWeek('ddd DD/MM/YYYY')}}
                        </td>
                        <td class="date" v-if="val['cpt'] > 0">
                            &nbsp; {{(val['med'] / val['cpt']) | Currency}}
                        </td>
                        <td class="date" v-if="val['cpt'] <= 0">
                            &nbsp;
                        </td>
                        <template v-for="(product) in products">
                            <td v-if="val[product.id] != null" :key="product.id" :class="minStyleDay(val[product.id], product, val['min'], val['minConc'])">
                                <span :id="'tooltipday_' + val[product.id]['id']" :class="'picto ' + pictoStyleDay(val[product.id], product)"></span>
                                <b-tooltip :target="'tooltipday_' + val[product.id]['id']" triggers="hover focus" customClass="tooltipClass" placement="topleft">
                                    Du&nbsp;{{getValueDay(val,product.id,'departureDate') | moment('DD/MM/YYYY')}}&nbsp;au&nbsp;{{getValueDay(val,product.id,'returnDate') | moment('DD/MM/YYYY')}}<br />
                                    Durée : {{product.duration}} jour<span v-if="product.duration > 1">s</span><br />
                                    <span v-if="isOpenDateValue(product, val[product.id]) == false">Fermé</span>
                                    <br v-if="isOpenDateValue(product, val[product.id]) == false" />
                                    <span v-if="val[product.id]['guaranteed'] && dateExistsDay(product, val[product.id]) ">Garanti le {{ val[product.id]['guaranteedDate'] | moment('DD/MM/YYYY')}}</span>
                                    <br v-if="val[product.id]['guaranteed'] && dateExistsDay(product, val[product.id]) " />
                                    Min pax : {{ getValueDay(val,product.id,'minParticipants') }}<br />
                                    Status : {{ getValueDay(val,product.id,'realStatus') }}<br />
                                    <span v-if="getValueDay(val,product.id,'taxValue') > 0">Supplément/jour inclus : {{ getValueDay(val,product.id,'daytaxeValue') | Currency }}</span>
                                    <span v-if="getValueDay(val,product.id,'valueSupp') > 0">Supplément/jour : {{ getValueDay(val,product.id,'dayvalueSupp')  | Currency }}</span>
                                </b-tooltip>
                                {{getValueDay(val,product.id,'dayvalue') | Currency}}
                            </td>
                            <td v-else :key="product.id">
                                -
                            </td>
                        </template>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script scoped>
    import moment from 'moment'
    import { mapState } from "vuex"

    export default {
        name: 'CalendarDayView',
        props: {
            products: Array,
            minQueryDate: String
        },
        event: ['selectcol', 'selectrow'],
        data() {
            return {
                loaded: 0,
                dateDetails: {},
                mindate: null,
                maxdate: null,
            }
        },
        methods: {
            pictoStyleDay: function (detail, product) {
                let taxe = ""

                if (detail.taxValue || detail.valueSupp) {
                    taxe += "taxe "
                }

                if (!this.isOpenDateDay(product, detail) && !this.dateExistsDay(product, detail)) {
                    return taxe + " greypicto"
                }

                if (detail.discount && detail.guaranteed) {
                    return taxe + " guaranteddiscountpicto"
                }

                if (detail.guaranteed) {
                    return taxe + " guarantedpicto"
                }

                if (detail.discount) {
                    return taxe + " discountpicto"
                }

                if (detail.taxValue || detail.valueSupp) {
                    return "taxepicto"
                }

                return taxe + "greypicto"
            },
            minStyleDay: function (detail, product, val, valConc) {

                let groupWebsite = [1, 5, 7];

                if (detail == null) {
                    return "";
                }
                if (!this.isOpenDateDay(product, detail)) {
                    return "closeddate"
                }
                if (!detail.registrable) {
                    return "unregistrable";
                }

                if (groupWebsite.indexOf(product.adapterId) < 0  ) {
                    if (detail.dayvalue == val) {
                        return "boldvalue"
                    }
                    return ""
                }
                if (detail.dayvalue == val) {
                    return "minvalue"
                }
                if (detail.dayvalue <= valConc) {
                    return ""
                }
                return "notminvalue";
            },
            getValueDay: function (obj, key1, key2) {
                if (obj[key1] == null) {
                    return "-";
                }
                return obj[key1][key2]
            },
            isOpenDateDay(product, detail) {
                if (!detail.open) {
                    return false;
                }
                let momentA = moment(detail.departureDate.substr(0, 10), "YYYY-MM-DD")
                let momentD = moment(detail.updateDate.substr(0, 10), "YYYY-MM-DD")
                let momentP = moment(product.updateDate.substr(0, 10), "YYYY-MM-DD")
                let momentB = moment();
                if (momentA.isBefore(momentB) || momentD.isBefore(momentP)) {
                    return false
                }
                return true
            },
            dateExistsDay(product, detail) {
                let momentA = moment(detail.departureDate.substr(0, 10), "YYYY-MM-DD")
                let momentD = moment(detail.updateDate.substr(0, 10), "YYYY-MM-DD")
                let momentP = moment(product.updateDate.substr(0, 10), "YYYY-MM-DD")
                let momentB = moment();
                if (this.minQueryDate != null) {
                    momentB = moment(this.minQueryDate, "YYYY-MM-DD")
                }
                if (momentA.isBefore(momentB) && momentD.isBefore(momentP)) {
                    return false
                }
                return true
            },
            isOpenDateValue(product, detail) {
                if (detail.open == false) {
                    return false;
                }
                let momentA = moment(detail.departureDate.substr(0, 10), "YYYY-MM-DD")
                let momentD = moment(detail.updateDate.substr(0, 10), "YYYY-MM-DD")
                let momentP = moment(product.updateDate.substr(0, 10), "YYYY-MM-DD")
                let momentB = moment();
                if (momentA.isBefore(momentB) || momentD.isBefore(momentP)) {
                    return false
                }
                return true
            }
        },
        computed: {
            weekDates() {
                let groupWebsite = [1, 5, 7];
                let weekdates = {};
                let defaultsObj = { 'min': null, 'med': 0, 'cpt': 0, 'minConc': 9999999, 'minHu' : null}
                this.products.forEach(product => {
                    defaultsObj[product.id] = null
                })

                this.products.forEach(product => {
                    product.details.forEach(detail => {
                        let key = detail.year + '-' + (detail.weekOfTheYear > 9 ? detail.weekOfTheYear : '0' + detail.weekOfTheYear)
                        if (weekdates[key] == undefined) {
                            weekdates[key] = Object.assign({}, defaultsObj)
                        }
                        

                        

                        if (product.duration == 0) {
                            detail.dayvalue = detail.value
                            detail.daytaxeValue = detail.taxValue
                            detail.dayvalueSupp = detail.valueSupp
                        } else {
                            detail.dayvalue = detail.value / product.duration
                            detail.daytaxeValue = detail.taxValue / product.duration
                            detail.dayvalueSupp = detail.valueSupp / product.duration
                        }
                        if (weekdates[key][product.id] != undefined && this.isOpenDateDay(product, detail)) {
                            weekdates[key][product.id] = detail
                        } else if (weekdates[key][product.id] == undefined) {
                            weekdates[key][product.id] = detail
                        }

                        if (this.isOpenDateDay(product, detail)) {
                            
                            if (weekdates[key]['min'] == null) {
                                weekdates[key]['min'] = detail.dayvalue
                            }

                            if (groupWebsite.indexOf(product.adapterId) < 0) {
                                weekdates[key]['minConc'] = Math.min(weekdates[key]['minConc'], detail.dayvalue)
                            } else {
                                if (weekdates[key]['minHu'] == null) {
                                    weekdates[key]['minHu'] = detail.dayvalue
                                }
                                weekdates[key]['minHu'] = Math.min(weekdates[key]['minHu'], detail.dayvalue)
                            }

                            weekdates[key]['med'] += detail.dayvalue
                            weekdates[key]['cpt'] += 1
                            weekdates[key]['min'] = Math.min(weekdates[key]['min'], detail.dayvalue)
                        }

                    })
                })

                let ordered = Object.keys(weekdates).sort().reduce(
                    (obj, key) => {
                        obj[key] = weekdates[key];
                        return obj;
                    },
                    {}
                )


                return ordered;
            },
            ...mapState(["currentTab", "currentDetail"])
        },
        mounted: function () {
        }
    }
</script>

<style scoped>
    .calview .boldvalue {
        font-weight: bold;
    }

    .calview .closevalue {
        text-decoration: line-through;
    }

    .calview .closeddate1 {
        color: #DDDDDD;
    }

    .calview .minvalue {
        font-weight: bold;
        color: green;
    }

    .calview td {
        border: 1px solid #808080;
        text-align: right;
        padding: 5px;
    }

        .calview td.date {
            width: 100px !important;
        }

    .calview th {
        background-color: #808080;
        color: #FFF;
        border: 1px solid #FFF;
        height: 35px;
    }

    .calview .notminvalue {
        font-weight: bold;
        color: red;
    }

    .calview .closeddate {
        color: grey;
        text-decoration: line-through;
    }

    .calview .unregistrable {
        text-decoration: line-through;
    }

    .calview tr:nth-child(even) {
        background-color: #dee2e6;
    }

    .picto {
        cursor: pointer;
        margin: -5px;
        float: left;
        width: 10px;
        height: 10px;
    }

    .greypicto {
        background-color: #DDDDDD;
    }

    .guaranteddiscountpicto {
        background-color: #4cff00;
    }

    .guarantedpicto {
        background-color: #0094ff;
    }

    .discountpicto {
        background-color: #e9e540;
    }

    .taxe {
        border: 2px solid red;
    }

    .taxepicto {
        background-color: #0094ff;
        border: 2px solid red;
    }

    .supp {
        border: 2px solid orange;
    }

    .supppicto {
        background-color: #0094ff;
        border: 2px solid orange;
    }

    .taxesupp {
        border-top: 2px solid red;
        border-left: 2px solid red;
        border-bottom: 2px solid orange;
        border-right: 2px solid orange;
    }

    .taxesupppicto {
        background-color: #0094ff;
        border-top: 2px solid red;
        border-left: 2px solid red;
        border-bottom: 2px solid orange;
        border-right: 2px solid orange;
    }

</style>
<style>
    .tooltipClass {
    }

    .tooltip-inner {
        text-align: left !important;
        background-color: #FFF;
        color: #000;
        border: 1px solid #000;
        min-width: 220px;
    }

</style>
