<template>
    <div>
        <div>
            <b-card no-body>
                <b-tabs pills card vertical nav-wrapper-class="w-10" content-class="w-90" >
                    <b-tab v-for="tabgroup in tabsList" :active="currentTab == tabgroup.name" :key="tabgroup.id" :rel="tabgroup.name" :id="'group_'+tabgroup.id" @click="Ok">
                        <template #title><b-icon-x-circle-fill class="float-icon" v-on:click="removeTab(tabgroup.id)"></b-icon-x-circle-fill>{{tabgroup.name}}</template>
                        <TabProducts :group="tabgroup"></TabProducts>
                    </b-tab>
                </b-tabs>
            </b-card>
        </div>
    </div>
</template>
<script>
    import { mapState } from "vuex"
    import TabProducts from './TabProducts.vue'
    export default {
        name: 'TabsGroup',
        components: {
            TabProducts
        },
        data() {
            return {
                currentTab: ""
            }
        },
        watch: {
            currentDetail: function (val) {
                if (this.currentTab == val)
                    return;
                let self = this
                setTimeout(() => {
                    self.currentTab = val
                }, 200)
                
            }
        },
        filters: {
        },
        props: {
        },
        methods: {
            Ok: function (el) {
                this.$store.commit("setCurrentDetail", el.target.text);
            },
            removeTab: function (tabId) {
                this.$store.commit("removeTabGroup", tabId);
            }
        },
        computed: {
            tabsList: function () {
                return Object.values(this.tabsgroup);
            },
            ...mapState(["webSites", "groupDurations", "countries", "groupCountries", "groupLevels", "groupTypes", "tabsgroup", "currentTab", "currentDetail", "previousTab"])
        },
        mounted: function () {
            
        }
    };
</script>
<style scoped>
    #catalogFilterArea {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    label {
        text-align: right !important
    }
    .div-checkable {
        margin-bottom: 5px;
        border-color: #AAAAAA;
        border-style: solid;
        border-width: 1px;
        float: left;
        min-width: 100px;
        width: 80%;
        height: 100px;
        margin-left: 0;
        overflow-y: auto;
        font-size: 1em;
        vertical-align: middle;
    }
    .container {
        max-width: 2000px;
    }
    input[type=checkbox].spaced {
        margin: 0.1em 0.3em 0 0.1em;
    }
    div.form-title {
        font-weight: bold;
        margin-bottom: 0px;
    }
    div.form-button {
        text-align: right;
    }
    .float-icon {
        float: right;
    }
</style>
