<template>
    <div>
        <b-card title="Zone géographique" v-show="action=='list'">
            <b-button pill size="sm" class="mb-2" variant="success" @click="createGCountry">
                <b-icon icon="plus-circle" aria-hidden="true"></b-icon>&nbsp;Ajouter
            </b-button>
            <b-table :items="gCountriesSort" :fields="gCountriesFields" head-variant="light">
                <template #cell(label)="data">
                    {{ data.value }}
                </template>
                <template #cell(countries)="data">
                    {{data.value|countryToLine}}
                </template>
                <template #cell(id)="data">
                    <b-button size="sm" class="mb-2" @click="editGCountry(data.value)">
                        <b-icon icon="gear-fill" aria-hidden="true"></b-icon>
                    </b-button>
                    <b-button size="sm" class="mb-2" @click="deleteGCountry(data.value, data.item.label)">
                        <b-icon icon="trash" aria-hidden="true"></b-icon>
                    </b-button>
                </template>
            </b-table>
        </b-card>
        <EditZoneGeo v-if="action=='edit'" :editId="editId" v-on:reset="resetForm"></EditZoneGeo>
        <CreateZoneGeo v-if="action=='create'" :editId="editId" v-on:reset="resetForm"></CreateZoneGeo>
    </div>
</template>

<script>
    import { mapState } from "vuex"
    import EditZoneGeo from './EditZoneGeo.vue'
    import CreateZoneGeo from './CreateZoneGeo.vue'
    import axios from 'axios'

    export default {
        name: 'ZoneGeo',
        components: {
            EditZoneGeo,
            CreateZoneGeo
        },
        data() {
            return {
                action: "list",
                editId: 0,
                gCountriesFields: [
                    {
                        key: 'label',
                        label: 'Zone Géographique'
                    },
                    {
                        key: 'countries',
                        label: 'Pays associés'
                    },
                    {
                        key: 'id',
                        label: 'Action'
                    }]
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return ''
                value = value.toString().toLowerCase()

                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            upper: function (value) {
                if (!value) return ''
                return value.toString().toUpperCase()
            },
            ln2br: function (value) {
                if (!value) return ''
                return value.toString().replace("\n", "<br>")
            },
            countryToLine: function (value) {
                if (value.length == 0) return ''
                let arrName = []
                value.forEach(vA => { arrName.push(vA.name) });
                arrName.sort((a, b) => {
                    return a.localeCompare(b);
                })
                return arrName.join(", ")
            },
        },
        props: {
        },
        methods: {
            deleteGCountry: function (id, name) {
                let self = this
                if (confirm("voulez-vous vraiment supprimer la zone " + name + " ? ")) {
                    axios.get(this.$ipService + '/api/GroupCountries/delete/' + id
                    ).then(function () {
                        self.$store.dispatch('loadGroupcountries')
                        self.$store.dispatch('loadCountries')
                        self.$store.dispatch('loadCountriesActives')
                        self.$store.dispatch('loadGrouptypes')
                        self.$store.dispatch('loadGrouplevels')
                        self.$store.dispatch('loadWebsites')
                        self.$store.dispatch('loadGroupdurations')
                        self.$store.dispatch('loadGroupproducts')
                    }).catch(function (error) {
                        console.log(error)
                    }).finally(() => {

                    })
                }
            },
            editGCountry: function (id) {
                this.editId = id
                this.action = 'edit'
            },
            createGCountry: function () {
                this.action = 'create'
            },
            resetForm: function () {
                this.action = 'list'
            }
        },
        computed: {
            gCountriesSort: function () {
                if (this.groupCountries == null) {
                    return []
                }
                let arrGCountries = this.groupCountries
                arrGCountries.sort((a, b) => {
                    return a.label.localeCompare(b.label);
                })
                return arrGCountries;
            },
            ...mapState(["webSites", "groupDurations", "countries", "groupCountries", "groupLevels", "groupTypes", "currentDetail", "currentUser"])
        },
        mounted: function () {
        }
    };
</script>

<style scoped>
    .icon-span {
        margin: 5px;
    }
    .clickable {
        cursor: pointer
    }
</style>
