<template>
    <b-card title="Nouvelle de durée">
        <b-form>
            <b-form-group id="input-group-1"
                          label="Nom :"
                          label-for="input-1">
                <b-form-input id="input-1"
                              type="text"
                              placeholder="Nom"
                              v-model="groupDuration.label"
                              required></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-1"
                          label="Min :"
                          label-for="input-1">
                <b-form-input id="input-1"
                              type="number"
                              placeholder="Min"
                              v-model="groupDuration.min"
                              required></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-1"
                          label="Max :"
                          label-for="input-1">
                <b-form-input id="input-1"
                              type="number"
                              placeholder="Max"
                              v-model="groupDuration.max"
                              required></b-form-input>
            </b-form-group>

            <b-button type="button" variant="primary" @click="postform">Submit</b-button>
            <b-button type="button" variant="danger" @click="resetform">Retour</b-button>
        </b-form>
    </b-card>
</template>

<script>
    import { mapState } from "vuex"
    import axios from 'axios'


    export default {
        name: 'CreateDuration',
        components: {
        },
        data() {
            return {
                allDurations: [],
                selectedDurations: [],
                listOptions: [],
                selectedOption: 'empty',
                groupDuration: { label: '', min: 0, max: 0 },
                selectOptions: [
                    { value: "all", text: "Tous les durations" },
                    { value: "empty", text: "Durations non affectés" },
                    { value: "group", text: "Durations du groupe uniquement" },
                    { value: "groups", text: "Durations déjà affectés uniquement" }
                ],
                action: "list",
                gCountriesFields: [
                    {
                        key: 'label',
                        label: 'Zone Géographique'
                    },
                    {
                        key: 'countries',
                        label: 'Pays associés'
                    },
                    {
                        key: 'id',
                        label: 'Action'
                    }]
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return ''
                value = value.toString().toLowerCase()

                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            upper: function (value) {
                if (!value) return ''
                return value.toString().toUpperCase()
            },
            ln2br: function (value) {
                if (!value) return ''
                return value.toString().replace("\n", "<br>")
            },
            countryToLine: function (value) {
                if (value.length == 0) return ''
                let arrName = []
                value.forEach(vA => { arrName.push(vA.name) });
                arrName.sort((a, b) => {
                    return a.localeCompare(b);
                })
                return arrName.join(", ")
            },
        },
        props: {
            editId: Number
        },
        event: ['reset', 'save'],
        methods: {
            resetform: function () {
                this.$emit('reset')
            },
            postform: function () {
                let objPost = {
                    label: this.groupDuration.label,
                    min: this.groupDuration.min,
                    max: this.groupDuration.max,
                }
                let self = this
                axios.post(this.$ipService + '/api/GroupDurations/', objPost
                ).then(function () {
                    self.$store.dispatch('loadGroupdurations')
                    self.$emit('reset')
                }).catch(function (error) {
                    console.log(error)
                }).finally(() => {

                })
            },
        },
        computed: {
            ...mapState(["webSites", "groupDurations", "countries", "groupCountries", "groupDurations", "groupTypes", "currentDetail", "currentUser"])
        },
        mounted: function () {
            self.groupDuration = {label: '', min: 0, max: 0}

        }
    };
</script>

<style scoped>
    .icon-span {
        margin: 5px;
    }

    .clickable {
        cursor: pointer
    }
</style>
