import { render, staticRenderFns } from "./LevelProduct.vue?vue&type=template&id=062d2eff&scoped=true"
import script from "./LevelProduct.vue?vue&type=script&lang=js"
export * from "./LevelProduct.vue?vue&type=script&lang=js"
import style0 from "./LevelProduct.vue?vue&type=style&index=0&id=062d2eff&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "062d2eff",
  null
  
)

export default component.exports