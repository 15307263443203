<template>
    <div class="calview">
        <div style="width: 100%">
            Légende :<br />
            <span class="minvalue">- Inférieur à la concurrence</span>&nbsp;<span class="notminvalue">- Supérieur à la concurrence</span>&nbsp;<span class="closeddate1">- N'est plus en ligne</span>&nbsp;<span class="boldvalue">- Prix le plus bas</span>&nbsp;<span class="closevalue">- N'est plus réservable</span>
        </div>
        <div style="width: 100%; height: 50px;">
            <div style="float:left;margin: 5px; padding: 10px;"><span style="margin:-10px;margin-top: 5px; float:left;background-color:#0094ff;width:10px;height:10px;"></span>Garanti</div>
            <div style="float:left;margin: 5px; padding: 10px;"><span style="margin:-10px;margin-top: 5px; float:left;background-color:#e9e540;width:10px;height:10px;"></span>Promo</div>
            <div style="float:left;margin: 5px; padding: 10px;"><span style="margin:-10px;margin-top: 5px; float:left;background-color:#4cff00;width:10px;height:10px;"></span>Promo & Garanti</div>
            <div style="float:left;margin: 5px; padding: 10px;"><span style="margin:-10px;margin-top: 5px; float:left;background-color:#DDD;width:10px;height:10px;border: 2px solid red;"></span>Supplément</div>
        </div>
        <table>
            <thead>
                <tr>
                    <th style="width:120px; text-align: center">
                        Semaine du
                    </th>
                    <th style="text-align: center">
                        Prix Moyen
                    </th>
                    <template v-for="(product, indexp) in products">
                        <th :key="indexp" style="text-align: center; width:100px;">
                            {{product.webSite.shortname}}-{{product.code}}
                        </th>
                    </template>
                </tr>
            </thead>
            <tbody>
                <template v-for="(val, key) in weekDates">
                    <tr :key="key">
                        <td class="date">
                            {{key|dayOfWeek('ddd DD/MM/YYYY')}}
                        </td>
                        <td class="date" v-if="val['cpt'] > 0">
                          {{(val['med'] / val['cpt']) | Currency}}
                        </td>
                        <td class="date" v-if="val['cpt'] <= 0">
                            &nbsp;
                        </td>
                        <template v-for="(product) in products">
                            <td v-if="val[product.id] != null" :key="product.id" :class="minStyle(val[product.id], product, val['min'], val['minConc'])">
                                <span :id="'tooltipval_' + val[product.id]['id']" :class="'picto ' + pictoStyle(val[product.id], product)"></span>
                                <b-tooltip :target="'tooltipval_' + val[product.id]['id']" triggers="hover focus" customClass="tooltipClass" placement="topleft">
                                    Du&nbsp;{{getValue(val,product.id,'departureDate') | moment('DD/MM/YYYY')}}&nbsp;au&nbsp;{{getValue(val,product.id,'returnDate') | moment('DD/MM/YYYY')}}<br />
                                    Durée : {{product.duration}} jour<span v-if="product.duration > 1">s</span><br />
                                    <span v-if="isOpenDate(product, val[product.id]) == false">Fermé</span>
                                    <br v-if="isOpenDate(product, val[product.id]) == false" />
                                    <span v-if="val[product.id]['guaranteed'] && dateExists(product, val[product.id]) ">Garanti le {{ val[product.id]['guaranteedDate'] | moment('DD/MM/YYYY')}}</span>
                                    <br v-if="val[product.id]['guaranteed'] && dateExists(product, val[product.id]) " />
                                    Min pax : {{ getValue(val,product.id,'minParticipants') }}<br />
                                    Status : {{ getValue(val,product.id,'realStatus') }}<br />
                                    <span v-if="getValue(val,product.id,'taxValue') > 0">Supplément inclus : {{ getValue(val,product.id,'taxValue')  | Currency }}</span>
                                    <span v-if="getValue(val,product.id,'valueSupp') > 0">Supplément : {{ getValue(val,product.id,'valueSupp')  | Currency }}</span>
                                </b-tooltip>
                                {{getValue(val,product.id,'value') | Currency}}
                            </td>
                            <td v-else :key="product.id">
                                -
                            </td>
                        </template>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script scoped>
    import moment from 'moment'
    import { mapState } from "vuex"

    export default {
        name: 'CalendarView',
        props: {
            products: Array,
            minQueryDate: String
        },
        event: ['selectcol', 'selectrow'],
        data() {
            return {
                loaded: 0,
                dateDetails: {},
                mindate: null,
                maxdate: null,
            }
        },
        methods: {
            pictoStyle: function (detail, product) {
                let taxe = ""
                if (detail.valueSupp || detail.taxValue) {
                    taxe = "taxe "
                }

                if (!this.isOpenDate(product, detail) && !this.dateExists(product, detail)) {
                    return taxe + "greypicto"
                }

                if (detail.discount && detail.guaranteed) {
                    return taxe + "guaranteddiscountpicto"
                }


                if (detail.guaranteed) {
                    return taxe + "guarantedpicto"
                }

                if (detail.discount) {
                    return taxe + "discountpicto"
                }

                if (detail.valueSupp || detail.taxValue) {
                    return "taxepicto"
                }


                return taxe + "greypicto"
            },
            minStyle: function (detail, product, val, valConc) {

                let groupWebsite = [1, 5, 7];

                if (detail == null) {
                    return "";
                }
                if (!this.isOpenDate(product, detail)) {
                    return "closeddate";
                }
                if (!detail.registrable) {
                    return "unregistrable";
                }
                if (groupWebsite.indexOf(product.adapterId) < 0  ) {
                    if (detail.value == val) {
                        return "boldvalue"
                    }
                    return ""
                }
                if (detail.value == val) {
                    return "minvalue"
                }
                if (detail.value <= valConc) {
                    return ""
                }
                return "notminvalue";
            },
            getValue: function (obj, key1, key2) {
                if (obj[key1] == null) {
                    return "-";
                }
                return obj[key1][key2]
            },
            isOpenDate(product, detail) {
                if (!detail.open) {
                    return false;
                }
                let momentA = moment(detail.departureDate.substr(0, 10), "YYYY-MM-DD")
                let momentD = moment(detail.updateDate.substr(0, 10), "YYYY-MM-DD")
                let momentP = moment(product.updateDate.substr(0, 10), "YYYY-MM-DD")
                let momentB = moment();
                if (momentA.isBefore(momentB) || momentD.isBefore(momentP)) {
                    return false
                }
                return true
            },
            dateExists(product, detail) {
                let momentA = moment(detail.departureDate.substr(0, 10), "YYYY-MM-DD")
                let momentD = moment(detail.updateDate.substr(0, 10), "YYYY-MM-DD")
                let momentP = moment(product.updateDate.substr(0, 10), "YYYY-MM-DD")
                let momentB = moment();
                if (this.minQueryDate != null) {
                    momentB = moment(this.minQueryDate, "YYYY-MM-DD")
                }
                if (momentA.isBefore(momentB) && momentD.isBefore(momentP)) {
                    return false
                }
                return true
            }
            
        },
        computed: {
            weekDates() {
                let groupWebsite = [1, 5, 7];
                let weekdates = {};
                let defaultsObj = {'min': null, 'med': 0, 'cpt': 0, 'minConc' : 999999999, 'minHu' : null}
                this.products.forEach(product => {
                    defaultsObj[product.id] = null
                })

                this.products.forEach(product => {
                    product.details.forEach(detail => {
                        let key = detail.year + '-' + (detail.weekOfTheYear > 9 ? detail.weekOfTheYear : '0' + detail.weekOfTheYear)
                        if (weekdates[key] == undefined) {
                            weekdates[key] = Object.assign({}, defaultsObj)
                        }


                        if (weekdates[key][product.id] != undefined && this.isOpenDate(product, detail)) {
                            weekdates[key][product.id] = detail
                        } else if (weekdates[key][product.id] == undefined) {
                            weekdates[key][product.id] = detail
                        }
                        if (this.isOpenDate(product, detail)) {
                            weekdates[key]['med'] += detail.value
                            weekdates[key]['cpt'] += 1
                            if (weekdates[key]['min'] == null) {
                                weekdates[key]['min'] = detail.value
                            }
                            weekdates[key]['min'] = Math.min(weekdates[key]['min'], detail.value)

                            if (groupWebsite.indexOf(product.adapterId) < 0) {
                                weekdates[key]['minConc'] = Math.min(weekdates[key]['minConc'], detail.value)
                            } else {
                                if (weekdates[key]['minHu'] == null) {
                                    weekdates[key]['minHu'] = detail.value
                                }
                                weekdates[key]['minHu'] = Math.min(weekdates[key]['minHu'], detail.value)
                            }

                        }
                    })
                })

                let ordered = Object.keys(weekdates).sort().reduce(
                    (obj, key) => {
                        obj[key] = weekdates[key];
                        return obj;
                    },
                    {}
                )


                return ordered;
            },
            ...mapState(["currentTab", "currentDetail"])
        },
        mounted: function () {
        }
    }
</script>

<style scoped>
    .calview .boldvalue {
        font-weight: bold;
    }
    .calview .closevalue {
        text-decoration: line-through;
    }

    .calview .minvalue {
        font-weight: bold;
        color: green;
    }

    .calview td {
        border: 1px solid #808080;
        text-align: right;
        padding: 5px;
    }

    .calview td.date {
        width: 120px!important;
    }

    .calview th {
        background-color: #808080;
        color: #FFF;
        border: 1px solid #FFF;
        height: 35px;
    }

    .calview .notminvalue {
        font-weight: bold;
        color: red;
    }

    .calview .closeddate {
        color: grey;
        text-decoration: line-through;
    }

    .calview .closeddate1 {
        color: grey;
    }

    .calview .unregistrable {
        text-decoration: line-through;
    }

    .calview tr:nth-child(even) {
        background-color: #dee2e6;
    }

    .picto {
        cursor: pointer;
        margin: -5px;
        float: left;
        width: 10px;
        height: 10px;
    }

    .greypicto {
        background-color: #DDDDDD;
    }

    .guaranteddiscountpicto {
        background-color: #4cff00;
    }

    .guarantedpicto {
        background-color: #0094ff;
    }

    .discountpicto {
        background-color: #e9e540;
    }

    .taxe {
        border: 2px solid red;
    }

    .supp {
        border: 2px solid orange;
    }

    .taxesupp {
        border-top: 2px solid red;
        border-left: 2px solid red;
        border-bottom: 2px solid orange;
        border-right: 2px solid orange;
    }

    .taxepicto {
        background-color: #DDDDDD;
        border: 2px solid red;
    }

    .supppicto {
        background-color: #DDDDDD;
        border: 2px solid orange;
    }

    .taxesupppicto {
        border-top: 2px solid red;
        border-left: 2px solid red;
        border-bottom: 2px solid orange;
        border-right: 2px solid orange;
        border: 2px solid red;
    }

    
</style>
