<template>
    <b-card title="Modification de niveau de produit">
        <b-form>
            <b-form-group id="input-group-1"
                          label="Nom :"
                          label-for="input-1"
                          >
                <b-form-input id="input-1"
                              type="text"
                              placeholder="Nom"
                              v-model="groupLevel.label"
                              required></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-3" label="Filtre :" label-for="input-3">
                <b-form-select id="input-3"
                               :options="selectOptions"
                               v-model="selectedOption"></b-form-select>
            </b-form-group>



            <b-form-group v-for="website in levelsList" :id="'input-group-' + website.id" :key="website.id" :label="website.name" label-for="input-3" v-show="website.enabled">
                    <b-form-checkbox-group v-model="selectedLevels"
                            :options="website.levels"
                            class="mb-3 fixed-with"
                            value-field="id"
                            text-field="label"></b-form-checkbox-group>
            </b-form-group>
                <b-button type="button" variant="primary" @click="postform">Submit</b-button>
                <b-button type="button" variant="warning" @click="resetform">Retour</b-button>
        </b-form>
    </b-card>
</template>

<script>
    import { mapState } from "vuex"
    import axios from 'axios'


    export default {
        name: 'EditLevelProduct',
        components: {
        },
        data() {
            return {
                allLevels: [],
                selectedLevels: [],
                listOptions: [],
                selectedOption: 'empty',
                groupLevel: {},
                selectOptions: [
                    { value: "all", text: "Tous les levels" },
                    { value: "empty", text: "Levels non affectés" },
                    { value: "group", text: "Levels du groupe uniquement" },
                    { value: "groups", text: "Levels déjà affectés uniquement" }
                ],
                action: "list",
                gCountriesFields: [
                    {
                        key: 'label',
                        label: 'Zone Géographique'
                    },
                    {
                        key: 'countries',
                        label: 'Pays associés'
                    },
                    {
                        key: 'id',
                        label: 'Action'
                    }]
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return ''
                value = value.toString().toLowerCase()

                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            upper: function (value) {
                if (!value) return ''
                return value.toString().toUpperCase()
            },
            ln2br: function (value) {
                if (!value) return ''
                return value.toString().replace("\n", "<br>")
            },
            countryToLine: function (value) {
                if (value.length == 0) return ''
                let arrName = []
                value.forEach(vA => { arrName.push(vA.name) });
                arrName.sort((a, b) => {
                    return a.localeCompare(b);
                })
                return arrName.join(", ")
            },
        },
        props: {
            editId: Number
        },
        event: ['reset', 'save'],
        methods: {
            resetform: function () {
                this.$emit('reset')
            },
            postform: function () {
                let objPost = {
                    id: this.groupLevel.id,
                    label: this.groupLevel.label,
                    levels: this.selectedLevels
                }
                let self = this
                axios.post(this.$ipService + '/api/GroupLevels/' + objPost.id, objPost
                ).then(function () {
                    self.$store.dispatch('loadGroupcountries')
                    self.$store.dispatch('loadCountries')
                    self.$store.dispatch('loadCountriesActives')
                    self.$store.dispatch('loadGrouptypes')
                    self.$store.dispatch('loadGrouplevels')
                    self.$store.dispatch('loadWebsites')
                    self.$store.dispatch('loadGroupdurations')
                    self.$store.dispatch('loadGroupproducts')
                    self.$emit('reset')
                }).catch(function (error) {
                    console.log(error)
                }).finally(() => {

                })
            },
            getEmptyList: function () {
                let result = []
                this.allLevels.sort((a, b) => {
                    return a.id - b.id
                })

                this.allLevels.forEach(website => {
                    let wSite = { id: website.id, name: website.name, enabled: website.enabled, levels: [] }
                    website.levels.forEach(objLevel => {
                        if (objLevel.groupLevelIds.length == 0) {
                            wSite.levels.push(objLevel)
                        }
                        if (objLevel.groupLevelIds.indexOf(this.editId) >= 0) {
                            wSite.levels.push(objLevel)
                        }
                    })
                    result.push(wSite)
                })
                return result
            },
            getAllList: function () {
                this.allLevels.sort((a, b) => {
                    return a.id - b.id
                })
                return this.allLevels
            },
            getGroupList: function () {
                let result = []
                this.allLevels.sort((a, b) => {
                    return a.id - b.id
                })

                this.allLevels.forEach(website => {
                    let wSite = { id: website.id, name: website.name, enabled: website.enabled, levels: [] }
                    website.levels.forEach(objLevel => {
                        if (objLevel.groupLevelIds.indexOf(this.editId) >= 0) {
                            wSite.levels.push(objLevel)
                        }
                    })
                    result.push(wSite)
                })
                return result
            },
            getGroupsList: function () {
                let result = []
                this.allLevels.sort((a, b) => {
                    return a.id - b.id
                })

                this.allLevels.forEach(website => {
                    let wSite = { id: website.id, name: website.name, enabled: website.enabled, levels: [] }
                    website.levels.forEach(objLevel => {
                        if (objLevel.groupLevelIds.length > 0) {
                            wSite.levels.push(objLevel)
                        }
                    })
                    result.push(wSite)
                })
                return result
            }
        },
        computed: {
            levelsList: function () {
                if (this.selectedOption == "all") {
                    return this.getAllList()
                }
                if (this.selectedOption == "empty") {
                    return this.getEmptyList()
                }
                if (this.selectedOption == "group") {
                    return this.getGroupList()
                }
                return this.getGroupsList()
            },
            ...mapState(["webSites", "groupDurations", "countries", "groupCountries", "groupLevels", "groupTypes", "currentDetail", "currentUser"])
        },
        mounted: function () {
            let self=this
            axios.get(this.$ipService + '/api/GroupLevels/soft/' + this.editId
            ).then(function (response) {
                self.groupLevel = response.data
            }).catch(function (error) {
                console.log(error);
            }).finally(() => {
                this.selectedLevels = []
                this.allLevels = []
                this.webSites.forEach(website => {
                    let objSite = { id: website.adapterId, name: website.name, enabled: website.enabled, levels: [] }
                    axios.get(this.$ipService + '/api/Levels/adapter/' + objSite.id
                    ).then(function (response) {
                        objSite.levels = []
                        self.allLevels.push(objSite)
                        response.data.forEach(objLevel => {
                            if (objLevel.active == false) {
                                return;
                            }
                            if (objLevel.groupLevelIds.indexOf(self.editId) >= 0) {
                                self.selectedLevels.push(objLevel.id);
                            }
                            objSite.levels.push(objLevel)
                        })
                    }).catch(function (error) {
                        console.log(error);
                    }).finally(() => {
                    })
                })


            })

            
        }
    };
</script>

<style scoped>
    .icon-span {
        margin: 5px;
    }

    .clickable {
        cursor: pointer
    }
</style>
