<template>
    <div id="div-variations">
        <div id="div-left">
            <div class="datePart">
                Depuis le {{currentUser.lastLogin | moment('DD/MM/YYYY')}}
            </div>
            <div v-show="onloading" style="text-align:center; vertical-align: middle; font-weight: bolder;"><div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div><div>Chargement</div></div>
            <div v-show="pricedown.length > 0">
                <h3 @click="pricedownshow = !pricedownshow"><b-icon v-show="!pricedownshow" icon="caret-right-fill" aria-hidden="true" /><b-icon v-show="pricedownshow" icon="caret-down-fill" aria-hidden="true" /> Baisses de prix ({{totalpricedown}})</h3>
                <ul v-show="pricedownshow">
                    <li @click="loadUserDateVariations('listpricedown', 0)">tout afficher</li>
                    <li v-for="price in pricedown" :key="price.adapterId" @click="loadUserDateVariations('listpricedown', price.adapterId)">{{price.adapterName}} : {{price.totalVariation}}</li>
                </ul>
            </div>
            <div v-show="priceup.length > 0">
                <h3 @click="priceupshow = !priceupshow"><b-icon v-show="!priceupshow" icon="caret-right-fill" aria-hidden="true" /><b-icon v-show="priceupshow" icon="caret-down-fill" aria-hidden="true" /> Hausses de prix ({{totalpriceup}})</h3>
                <ul v-show="priceupshow">
                    <li @click="loadUserDateVariations('listpriceup', 0)">tout afficher</li>
                    <li v-for="price in priceup" :key="price.adapterId" @click="loadUserDateVariations('listpriceup', price.adapterId)">{{price.adapterName}} : {{price.totalVariation}}</li>
                </ul>
            </div>
            <div v-show="promo.length > 0">
                <h3 @click="promoshow = !promoshow"><b-icon v-show="!promoshow" icon="caret-right-fill" aria-hidden="true" /><b-icon v-show="promoshow" icon="caret-down-fill" aria-hidden="true" /> Promotions ({{totalpromo}})</h3>
                <ul v-show="promoshow">
                    <li @click="loadUserDateVariations('listpromo', 0)">tout afficher</li>
                    <li v-for="price in promo" :key="price.adapterId" @click="loadUserDateVariations('listpromo', price.adapterId)">{{price.adapterName}} : {{price.totalVariation}}</li>
                </ul>
            </div>
            <div v-show="promoup.length > 0">
                <h3 @click="promoupshow = !promoupshow"><b-icon v-show="!promoupshow" icon="caret-right-fill" aria-hidden="true" /><b-icon v-show="promoupshow" icon="caret-down-fill" aria-hidden="true" /> Sur-promotions ({{totalpromoup}})</h3>
                <ul v-show="promoupshow">
                    <li @click="loadUserDateVariations('listpromoup', 0)">tout afficher</li>
                    <li v-for="price in promoup" :key="price.adapterId" @click="loadUserDateVariations('listpromoup', price.adapterId)">{{price.adapterName}} : {{price.totalVariation}}</li>
                </ul>
            </div>
            <div v-show="garanti.length > 0">
                <h3 @click="garantishow = !garantishow"><b-icon v-show="!garantishow" icon="caret-right-fill" aria-hidden="true" /><b-icon v-show="garantishow" icon="caret-down-fill" aria-hidden="true" /> Départs garantis ({{totalgaranti}})</h3>
                <ul v-show="garantishow">
                    <li @click="loadUserDateVariations('listgaranti', 0)">tout afficher</li>
                    <li v-for="price in garanti" :key="price.adapterId" @click="loadUserDateVariations('listgaranti', price.adapterId)">{{price.adapterName}} : {{price.totalVariation}}</li>
                </ul>
            </div>
            <div v-show="close.length > 0">
                <h3 @click="closeshow = !closeshow"><b-icon v-show="!closeshow" icon="caret-right-fill" aria-hidden="true" /><b-icon v-show="closeshow" icon="caret-down-fill" aria-hidden="true" /> Dates fermées ({{totalclose}})</h3>
                <ul v-show="closeshow">
                    <li @click="loadUserDateVariations('listclose', 0)">tout afficher</li>
                    <li v-for="price in close" :key="price.adapterId" @click="loadUserDateVariations('listclose', price.adapterId)">{{price.adapterName}} : {{price.totalVariation}}</li>
                </ul>
            </div>
            <div v-show="deletedate.length > 0">
                <h3 @click="deletedateshow = !deletedateshow"><b-icon v-show="!deletedateshow" icon="caret-right-fill" aria-hidden="true" /><b-icon v-show="deletedateshow" icon="caret-down-fill" aria-hidden="true" /> Dates supprimées ({{totaldeletedate}})</h3>
                <ul v-show="deletedateshow">
                    <li @click="loadUserDateVariations('listdelete', 0)">tout afficher</li>
                    <li v-for="price in deletedate" :key="price.adapterId" @click="loadUserDateVariations('listdelete', price.adapterId)">{{price.adapterName}} : {{price.totalVariation}}</li>
                </ul>
            </div>
            <div v-show="newdate.length > 0">
                <h3 @click="newdateshow = !newdateshow"><b-icon v-show="!newdateshow" icon="caret-right-fill" aria-hidden="true" /><b-icon v-show="newdateshow" icon="caret-down-fill" aria-hidden="true" /> Nouvelles dates ({{totalnewdate}})</h3>
                <ul v-show="newdateshow">
                    <li @click="loadUserDateVariations('listnew', 0)">tout afficher</li>
                    <li v-for="price in newdate" :key="price.adapterId" @click="loadUserDateVariations('listnew', price.adapterId)">{{price.adapterName}} : {{price.totalVariation}}</li>
                </ul>
            </div>
            <div v-show="newprod.length > 0">
                <h3 @click="newprodshow = !newprodshow"><b-icon v-show="!newprodshow" icon="caret-right-fill" aria-hidden="true" /><b-icon v-show="newprodshow" icon="caret-down-fill" aria-hidden="true" /> Nouveaux produits ({{totalnewprod}})</h3>
                <ul v-show="newprodshow">
                    <li @click="loadUserDateVariations('listnewprod', 0)">tout afficher</li>
                    <li v-for="price in newprod" :key="price.adapterId" @click="loadUserDateVariations('listnewprod', price.adapterId)">{{price.adapterName}} : {{price.totalVariation}}</li>
                </ul>
            </div>
            <div v-show="oldprod.length > 0">
                <h3 @click="oldprodshow = !oldprodshow"><b-icon v-show="!oldprodshow" icon="caret-right-fill" aria-hidden="true" /><b-icon v-show="oldprodshow" icon="caret-down-fill" aria-hidden="true" /> Produits supprimés ({{totaloldprod}})</h3>
                <ul v-show="oldprodshow">
                    <li @click="loadUserDateVariations('listoldprod', 0)">tout afficher</li>
                    <li v-for="price in oldprod" :key="price.adapterId" @click="loadUserDateVariations('listoldprod', price.adapterId)">{{price.adapterName}} : {{price.totalVariation}}</li>
                </ul>
            </div>
            <div v-show="updatedprod.length > 0">
                <h3 @click="updatedprodshow = !updatedprodshow"><b-icon v-show="!updatedprodshow" icon="caret-right-fill" aria-hidden="true" /><b-icon v-show="updatedprodshow" icon="caret-down-fill" aria-hidden="true" /> Produits mis à jour ({{totalupdatedprod}})</h3>
                <ul v-show="updatedprodshow">
                    <li @click="loadUserUpdatedProducts(0)">tout afficher</li>
                    <li v-for="price in updatedprod" :key="price.adapterId" @click="loadUserUpdatedProducts( price.adapterId)">{{price.adapterName}} : {{price.totalVariation}}</li>
                </ul>
            </div>
        </div>
        <div id="content-variations">
            <b-jumbotron id="variationFilterArea">
                <div class="form-group row form-title">
                    <div class="form-title col"><label class="form-check-label">Sites&nbsp;:</label></div>
                </div>
                <div class="form-group row">
                    <div class="col">
                        <div v-for="website in activesWebSites" :key="website.id" class="form-check form-check-inline">
                            <b-form-checkbox switch class="form-check-input" :id="'websiteCheckbox'+website.id" :value="website.id" v-model="websitesChk">
                                {{ website.name | capitalize }}
                            </b-form-checkbox>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col form-title">
                        <label class="form-check-label">Zone G&eacute;o : </label>
                    </div>
                    <div class="col">
                        <label class="form-check-label">Pays : </label>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="form-check col">
                        <div class="div-checkable">
                            <div v-for="gCountry in groupCountries" :key="gCountry.id" :value="gCountry.id">
                                <b-form-checkbox switch class="spaced" :id="'filtergCountry'+gCountry.id" :value="gCountry.id" @change="loadCountries" v-model="gCountriesChk">{{ gCountry.label | capitalize }}</b-form-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="form-check col">
                        <div class="div-checkable">
                            <div v-for="country in lcountries" :key="country.id" :value="country.id">
                                <b-form-checkbox switch class="spaced" type="checkbox" :id="'filtercountry'+country.id" :value="country.id" v-model="countriesChk">{{ country.name | capitalize }}</b-form-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row form-title">
                    <div class="col">
                        <label class="form-check-label">Depuis le : </label>
                    </div>
                    <div class="col">
                        <label class="form-check-label">Type : </label>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="form-check col">
                        <b-form-input v-model="dateFrom" type="date"></b-form-input>
                    </div>
                    <div class="form-check col">
                        <b-form-select :options="variationOptions" v-model="variationType"></b-form-select>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="form-button col">
                        <b-button variant="outline-primary" v-on:click="searchProducts()">Filtrer</b-button>
                    </div>
                </div>
            </b-jumbotron>
            <div v-show="loadingResult" style="text-align:center; vertical-align: middle; font-weight: bolder;"><div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div><div>Chargement</div></div>
            <div div="div-results" v-show="!loadingResult">
                <b-table-simple hover small caption-top responsive v-if="variationsPrice.length > 0">
                    <b-thead head-variant="dark">
                        <b-tr>
                            <b-th>&nbsp;</b-th>
                            <b-th>Code</b-th>
                            <b-th>Site</b-th>
                            <b-th>Groupes Produits</b-th>
                            <b-th>Durée</b-th>
                            <b-th>Pays</b-th>
                            <b-th>Date départ</b-th>
                            <b-th>Date retour</b-th>
                            <b-th>Prix TTC</b-th>
                            <b-th>Promo</b-th>
                            <b-th>Min Pax</b-th>
                            <b-th>Ouvert</b-th>
                            <b-th>Date Garantie</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <template v-for="(variationPrice, varPIdx) in variationsPrice">
                            <b-tr :class="{'ojj':varPIdx % 2, 'odd': !(varPIdx % 2)}" :key="varPIdx">
                                <b-td v-if="variationPrice.variations.length <= 1">&nbsp;</b-td>
                                <b-td @click="changeDetail(varPIdx)" v-if="variationPrice.variations.length > 1" class="pointer">
                                    <b-icon v-show="!detailShow(varPIdx)" icon="caret-right-fill" aria-hidden="true" />
                                    <b-icon v-show="detailShow(varPIdx)" icon="caret-down-fill" aria-hidden="true" />
                                </b-td>
                                <b-td><span class="pointer" v-on:click="addProduct(variationPrice.firstVariation, false, $event)" @contextmenu="addProduct(variationPrice.firstVariation, true, $event)">{{variationPrice.firstVariation.productCode}}</span></b-td>
                                <b-td>{{variationPrice.firstVariation.website.name}}</b-td>
                                <b-td>
                                    <template v-for="(groupProducts) in variationPrice.firstVariation.groupsProducts">
                                        <span :key="groupProducts.groupProductId" class="pointer" v-on:click="addGroupProduct(groupProducts.groupProductId, false, $event)" @contextmenu="addGroupProduct(groupProducts.groupProductId, true, $event)">{{groupProducts.groupProductCode}}&nbsp;<b-icon v-show="groupProducts.groupProductComment != undefined && groupProducts.groupProductComment.length > 0" :title="groupProducts.groupProductComment" icon="chat-left-dots" aria-hidden="true" /></span>
                                        <br :key="groupProducts.groupProductId" />
                                    </template>
                                </b-td>
                                <b-td>{{variationPrice.firstVariation.productDuration}}</b-td>
                                <b-td><img v-for="country in variationPrice.firstVariation.productCountries" :key="country.id" :src="'/flags/'+country.id.toLowerCase().substr(0,2)+'.gif'" :alt="country.name" :title="country.name" /></b-td>
                                <template v-if="variationPrice.variations.length <= 1">
                                    <b-td><span v-if="variationPrice.firstVariation.dateDeparture != ''">{{variationPrice.firstVariation.dateDeparture | moment('DD/MM/YYYY')}}</span></b-td>
                                    <b-td><span v-if="variationPrice.firstVariation.dateDeparture != ''">{{variationPrice.firstVariation.dateDeparture | moment('DD/MM/YYYY')}}</span></b-td>
                                    <b-td>{{variationPrice.firstVariation.priceValue}} <span v-if="variationPrice.firstVariation.oldPriceValue > 0" :inner-html.prop="(variationPrice.firstVariation.priceValue - variationPrice.firstVariation.oldPriceValue) | Currency('color', false)" /></b-td>
                                    <b-td>{{variationPrice.firstVariation.promoValue}} <span v-if="variationPrice.firstVariation.oldPromoValue > 0" :inner-html.prop="(variationPrice.firstVariation.promoValue - variationPrice.firstVariation.oldPromoValue) | Currency('color', true)" /></b-td>
                                    <b-td>{{variationPrice.firstVariation.minTravellers}}</b-td>
                                    <b-td><span v-if="variationPrice.firstVariation.registrable">OUI</span><span v-if="!variationPrice.firstVariation.registrable">NON</span></b-td>
                                    <b-td><span v-if="variationPrice.firstVariation.guaranteed"><span v-if="variationPrice.firstVariation.guaranteedUpdated != ''">{{variationPrice.firstVariation.guaranteedUpdated | moment('DD/MM/YYYY')}}</span></span><span v-if="!variationPrice.firstVariation.guaranteed">NON</span></b-td>
                                </template>
                                <template v-if="variationPrice.variations.length > 1">
                                    <b-td colspan="7"> {{variationPrice.variations.length}} départs </b-td>
                                </template>
                            </b-tr>
                            <template v-if="variationPrice.variations.length > 1">
                                <b-tr :class="{'ojj1': idx % 2, 'odd1': !(idx % 2)}" v-show="detailShow(varPIdx)" :id="'vardetail-'+ varPIdx + '-' + idx" v-for="(variation, idx) in variationPrice.variations" :key="varPIdx + '-' + idx">
                                    <b-td>&nbsp;</b-td>
                                    <b-td>&nbsp;</b-td>
                                    <b-td>&nbsp;</b-td>
                                    <b-td>&nbsp;</b-td>
                                    <b-td>{{variation.productDuration}}</b-td>
                                    <b-td><img v-for="country in variation.productCountries" :key="country.id" :src="'/flags/'+country.id.toLowerCase()+'.gif'" :alt="country.name" :title="country.name" /></b-td>
                                    <b-td><span v-if="variationPrice.dateDeparture != ''">{{variation.dateDeparture | moment('DD/MM/YYYY')}}</span></b-td>
                                    <b-td><span v-if="variationPrice.dateReturn != ''">{{variation.dateReturn | moment('DD/MM/YYYY')}}</span></b-td>
                                    <b-td>{{variation.priceValue}} <span v-if="variation.oldPriceValue > 0" :inner-html.prop="(variation.priceValue - variation.oldPriceValue) |  Currency('color', false)" /></b-td>
                                    <b-td>{{variation.promoValue}} <span v-if="variation.oldPromoValue > 0" :inner-html.prop="(variation.promoValue - variation.oldPromoValue) |  Currency('color', true)" /></b-td>
                                    <b-td>{{variation.minTravellers}}</b-td>
                                    <b-td><span v-if="variation.registrable">OUI</span><span v-if="!variation.registrable">NON</span></b-td>
                                    <b-td><span v-if="variation.guaranteed"><span v-if="variationPrice.guaranteedUpdated != ''">{{variation.guaranteedUpdated | moment('DD/MM/YYYY')}}</span></span><span v-if="!variation.guaranteed">NON</span></b-td>
                                </b-tr>
                            </template>
                        </template>
                    </b-tbody>
                </b-table-simple>
                <b-table-simple hover small caption-top responsive v-if="updatedProducts.length > 0">
                    <b-thead head-variant="dark">
                        <b-tr>
                            <b-th>&nbsp;</b-th>
                            <b-th>Code</b-th>
                            <b-th>Site</b-th>
                            <b-th>Groupes Produits</b-th>
                            <b-th>Titre</b-th>
                            <b-th>Durée</b-th>
                            <b-th>Pays</b-th>
                            <b-th>Types</b-th>
                            <b-th>Niveaux</b-th>
                            <b-th>Url</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <template v-for="(updatedProduct, varPIdx) in updatedProducts">
                            <b-tr :class="{'ojj':varPIdx % 2, 'odd': !(varPIdx % 2)}" :key="varPIdx">
                                <b-td @click="changeDetail(varPIdx)" class="pointer">
                                    <b-icon v-show="!detailShow(varPIdx)" icon="caret-right-fill" aria-hidden="true" />
                                    <b-icon v-show="detailShow(varPIdx)" icon="caret-down-fill" aria-hidden="true" />
                                </b-td>
                                <b-td><span class="pointer" v-on:click="addProduct(updatedProduct, false, $event)" @contextmenu="addProduct(updatedProduct, true, $event)">{{updatedProduct.code}}</span></b-td>
                                <b-td>{{updatedProduct.website.name}}</b-td>
                                <b-td>
                                    <template v-for="(groupProducts) in updatedProduct.groupsProducts">
                                        <span :key="groupProducts.groupProductId" class="pointer" v-on:click="addGroupProduct(groupProducts.groupProductId, false, $event)" @contextmenu="addGroupProduct(groupProducts.groupProductId, true, $event)">{{groupProducts.groupProductCode}}&nbsp;<b-icon v-show="groupProducts.groupProductComment != undefined && groupProducts.groupProductComment.length > 0" :title="groupProducts.groupProductComment" icon="chat-left-dots" aria-hidden="true" /></span>
                                        <br :key="groupProducts.groupProductId" />
                                    </template>
                                </b-td>
                                <b-td :title="updatedProduct.title" class="product-title">{{updatedProduct.title | truncate(40, '...')}}</b-td>
                                <b-td>{{updatedProduct.duration}}</b-td>
                                <b-td><img v-for="country in updatedProduct.countries" :key="country.id" :src="'/flags/'+country.id.toLowerCase().substr(0,2)+'.gif'" :alt="country.name" :title="country.name" /></b-td>
                                <b-td><span v-for="gtype in updatedProduct.groupTypes" :key="gtype.id">{{gtype.label}}</span></b-td>
                                <b-td><span v-for="glevel in updatedProduct.groupLevels" :key="glevel.id">{{glevel.label}}</span></b-td>
                                <b-th><a :href="updatedProduct.urlPage" target="_blank">{{updatedProduct.urlPage | truncate(20, '...')}}</a></b-th>
                            </b-tr>
                            <b-tr :key="varPIdx" v-show="detailShow(varPIdx)" style="background-color: aliceblue">
                                <b-td colspan="2">
                                    <b>Titre</b>
                                </b-td>
                                <b-td colspan="4" :class="{'modifval' : updatedProduct.logTitle != updatedProduct.title, 'product-title' : updatedProduct.title}">
                                    <span :title="updatedProduct.logTitle">{{updatedProduct.logTitle | truncate(40, '...')}}</span>
                                </b-td>
                                <b-td colspan="4" class="product-title">
                                    <span :title="updatedProduct.title">{{updatedProduct.title | truncate(40, '...')}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr :key="varPIdx" v-show="detailShow(varPIdx)" style="background-color: lightgoldenrodyellow">
                                <b-td colspan="2">
                                    <b>Url</b>
                                </b-td>
                                <b-td colspan="4" :class="{'modifval' : updatedProduct.logProduct.urlPage != updatedProduct.product.urlPage}">
                                    <span :title="updatedProduct.logProduct.urlPage">{{updatedProduct.logProduct.urlPage | truncate(40, '...')}}</span>
                                </b-td>
                                <b-td colspan="4" class="product-title">
                                    <span :title="updatedProduct.product.urlPage">{{updatedProduct.product.urlPage | truncate(40, '...')}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr :key="varPIdx" v-show="detailShow(varPIdx)" style="background-color: aliceblue">
                                <b-td colspan="2">
                                    <b>Durée</b>
                                </b-td>
                                <b-td colspan="4" :class="{modifval : updatedProduct.logDuration != updatedProduct.duration  }">
                                    {{updatedProduct.logDuration}}
                                </b-td>
                                <b-td colspan="4">
                                    {{updatedProduct.duration}}
                                </b-td>
                            </b-tr>
                            <b-tr :key="varPIdx" v-show="detailShow(varPIdx)" style="background-color: lightgoldenrodyellow">
                                <b-td colspan="2">
                                    <b>Min pax</b>
                                </b-td>
                                <b-td colspan="4" :class="{modifval : updatedProduct.logProduct.minTravellers != updatedProduct.product.minTravellers  }">
                                    {{updatedProduct.logProduct.minTravellers}}
                                </b-td>
                                <b-td colspan="4">
                                    {{updatedProduct.product.minTravellers}}
                                </b-td>
                            </b-tr>
                            <b-tr :key="varPIdx" v-show="detailShow(varPIdx)" style="background-color: aliceblue">
                                <b-td colspan="2">
                                    <b>Pays</b>
                                </b-td>
                                <b-td colspan="4" :class="{modifval : updatedProduct.logCountriesDebug != updatedProduct.countriesDebug  }">
                                    {{updatedProduct.logCountriesDebug}}
                                </b-td>
                                <b-td colspan="4">
                                    {{updatedProduct.countriesDebug}}
                                </b-td>
                            </b-tr>
                            <b-tr :key="varPIdx" v-show="detailShow(varPIdx)" style="background-color: lightgoldenrodyellow">
                                <b-td colspan="2">
                                    <b>Types</b>
                                </b-td>
                                <b-td colspan="4" :class="{modifval : updatedProduct.logTypesDebug != updatedProduct.typesDebug  }">
                                    {{updatedProduct.logTypesDebug}}
                                </b-td>
                                <b-td colspan="4">
                                    {{updatedProduct.typesDebug}}
                                </b-td>
                            </b-tr>
                            <b-tr :key="varPIdx" v-show="detailShow(varPIdx)" style="background-color: aliceblue">
                                <b-td colspan="2">
                                    <b>Niveau</b>
                                </b-td>
                                <b-td colspan="4" :class="{modifval : updatedProduct.logLevelDebug != updatedProduct.levelDebug  }">
                                    {{updatedProduct.logLevelDebug}}
                                </b-td>
                                <b-td colspan="4">
                                    {{updatedProduct.levelDebug}}
                                </b-td>
                            </b-tr>
                        </template>
                    </b-tbody>
                </b-table-simple>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex"
    import axios from 'axios'
    import moment from 'moment'
    export default {
        name: 'Variations',
        components: {
        },
        props: {
        },
        filters: {
            capitalize: function (value) {
                if (!value) return ''
                value = value.toString().toLowerCase()

                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            sortValues: function (values) {
                if (!values.length) return '';
                if (!Array.isArray(values)) return '';
                let valReturn = "";
                values.forEach(el => {
                    if (el.label != undefined) {
                        valReturn = valReturn + "-" + el.label
                    }
                    if (el.name != undefined) {
                        valReturn = valReturn + "-" + el.name
                    }
                })
                return valReturn
            }
        },
        data() {
            return {
                onloading: true,
                loadingResult: false,
                varDetailShow: {},
                currentClass: 'odd',
                currentSubClass: 'odd1',
                updatedProducts: [],
                variationsPrice: [],
                variationType: "pricedown",
                websitesChk: [],
                gCountriesChk: [],
                countriesChk: [],
                pricedownshow: false,
                priceupshow: false,
                promoshow: false,
                promoupshow: false,
                garantishow: false,
                closeshow: false,
                deletedateshow: false,
                newdateshow: false,
                newprodshow: false,
                oldprodshow: false,
                updatedprodshow: false,
                pricedown: [],
                priceup: [],
                promo: [],
                promoup: [],
                garanti: [],
                close: [],
                deletedate: [],
                newdate: [],
                newprod: [],
                oldprod: [],
                updatedprod: [],
                totalpricedown: 0,
                totalpriceup: 0,
                totalpromo: 0,
                totalpromoup: 0,
                totalgaranti: 0,
                totalclose: 0,
                totaldeletedate: 0,
                totalnewdate: 0,
                totalnewprod: 0,
                totaloldprod: 0,
                totalupdatedprod: 0,
                lcountries: [],
                dateFrom: null,
                variationOptions: [
                    {
                        value: 'pricedown', text: 'Tarifaire - Baisses de prix'
                    },
                    {
                        value: 'priceup', text: 'Tarifaire - Hausses de prix'
                    },
                    {
                        value: 'promo', text: 'Tarifaire - Promotions'
                    },
                    {
                        value: 'promoup', text: 'Tarifaire - SurPromotion'
                    },
                    {
                        value: 'garanti', text: 'Remplissage - Garantis'
                    },
                    {
                        value: 'close', text: 'Dates - Fermetures'
                    },
                    {
                        value: 'delete', text: 'Dates - Suppressions'
                    },
                    {
                        value: 'new', text: 'Dates - Ouvertures'
                    },
                    {
                        value: 'newprod', text: 'Nouveaux Produits'
                    },
                    {
                        value: 'oldprod', text: 'Produits Supprimés'
                    },
                    {
                        value: 'updatedprod', text: 'Produits Modifiés'
                    }
                ]
            }
        },
        watch: {

        },
        computed: {
            ...mapState(["webSites", "currentUser", "groupCountries", "countries", "activesWebSites"]),
        },
        methods: {
            addGroupProduct: function (groupid, showDetail, e) {
                e.preventDefault()
                let self = this
                let urlLoad = this.$ipService + '/api/GroupProducts/' + groupid 
                axios.get(urlLoad).then(response => {
                    let group = response.data
                    self.$store.commit("addTabGroup", { id: group.id, name: group.label, type: "group", group: group })
                    self.$store.commit("setCurrentDetail", group.label)
                    if (showDetail) {
                        self.$store.commit('setCurrentTab', 'detail')
                    }
                })

                
            },
            addProduct: function (variation, showDetail, e) {
                e.preventDefault()
                let groupId = 'PROD_' + variation.productId
                let compName = 'PROD_' + variation.productCode

                this.$store.commit("addTabGroup", { id: groupId, name: compName, type: "comparaison", products: [variation.productId] })
                this.$store.commit("setCurrentDetail", compName)
                if (showDetail) {
                    this.$store.commit('setCurrentTab', 'detail')
                }
            },
            changeDetail: function (key) {
                let localDetail = this.varDetailShow;
                if (localDetail[key] != undefined) {
                    localDetail[key] = !localDetail[key];
                } else {
                    localDetail[key] = true
                }
                this.varDetailShow = { ...localDetail }
            },
            detailShow: function (key) {
                if (this.varDetailShow[key] != undefined) {
                    return this.varDetailShow[key];
                }
                return false;
            },
            getCurrentClass: function () {
                this.currentSubClass = 'odd1'
                if (this.currentClass == 'odd') {
                    this.currentClass = 'ojj'
                } else {
                    this.currentClass = 'odd'
                }
                return this.currentClass
            },
            getCurrentSubClass: function () {
                if (this.currentSubClass == 'odd1') {
                    this.currentSubClass = 'ojj1'
                } else {
                    this.currentSubClass = 'odd1'
                }
                return this.currentSubClass
            },
            getWebSite: function (id) {
                for (let i = 0; i < this.webSites.length; i++) {
                    if (id == this.webSites[i].id) {
                        return this.webSites[i];
                    }
                }
                return null
            },
            getCountry: function (id) {
                for (let i = 0; i < this.countries.length; i++) {
                    if (id == this.countries[i].id) {
                        return { "id": this.countries[i].id, "name": this.countries[i].name };
                    }
                }
                return null
            },
            loadUserUpdatedProducts: function (adpaterdId) {
                if (this.loadingResult) {
                    return;
                }
                this.loadingResult = true;
                let self = this
                this.variationsPrice = []
                this.updatedProducts = []
                this.varDetailShow = {}
                let dateSearch = moment(this.currentUser.lastLogin.substr(0, 10)).add(1, 'days').format('YYYY-MM-DD')
                let urlLoad = this.$ipService + '/api/products/listupdatedprod/' + dateSearch + "/" + this.currentUser.id + "/" + adpaterdId
                axios.get(urlLoad).then(response => {
                    let updatedProducts = response.data
                    updatedProducts.sort((a, b) => {
                        if (a.groupProductCode != b.groupProductCode) {
                            return a.groupProductCode.localeCompare(b.groupProductCode)
                        }
                        if (a.productCode != b.productCode) {
                            return a.productCode.localeCompare(b.productCode)
                        }
                        return a.adapterId - b.adapterId
                    });

                    updatedProducts.forEach(variation => {
                        variation.website = self.getWebSite(variation.adapterId)
                        variation.productId = variation.id
                        variation.productCode = variation.code
                        self.updatedProducts.push(variation)
                    })

                }
                ).catch(error => {
                    console.log("error")
                    console.log(error)
                }).finally(() => {
                    self.loadingResult = false
                })

            },
            loadUserDateVariations: function (typeSearch, adpaterdId) {
                if (this.loadingResult) {
                    return;
                }
                this.loadingResult = true;
                let self = this
                this.variationsPrice = []
                this.updatedProducts = []
                this.varDetailShow = {}
                let dateSearch = moment(this.currentUser.lastLogin.substr(0, 10)).add(1, 'days').format('YYYY-MM-DD')                
                let urlLoad = this.$ipService + '/api/products/' + typeSearch + '/' + dateSearch + "/" + this.currentUser.id + "/" + adpaterdId
                axios.get(urlLoad).then(response => {
                    let variationsPrice = response.data
                    variationsPrice.sort((a, b) => {
                        if (a.adapterId != b.adapterId) {
                            return a.adapterId - b.adapterId
                        }
                        if (a.productCode != b.productCode) {
                            return a.productCode.localeCompare(b.productCode)
                        }
                        if (a.dateDeparture == undefined || a.dateDeparture == null) {
                            a.dateDeparture = ""
                        }
                        if (b.dateDeparture == undefined || b.dateDeparture == null) {
                            b.dateDeparture = ""
                        }
                        return a.dateDeparture.localeCompare(b.dateDeparture)
                    });
                    let currentObjVariation = {
                        key: "", variations: [], firstVariation: {}
                    }
                    variationsPrice.forEach(variation => {
                        variation.website = self.getWebSite(variation.adapterId)
                        let listCountries = [];
                        let lCountries = variation.productCountries.split(";")
                        lCountries.forEach(countyCode => {
                            let searchCountry = self.getCountry(countyCode)
                            if (searchCountry != null) {
                                listCountries.push(searchCountry)
                            }
                        })
                        variation.productCountries = listCountries
                        let checkVariationCode = variation.adapterId + "_" + variation.productCode;
                        if (currentObjVariation.key != checkVariationCode) {
                            if (currentObjVariation.variations.length > 0) {
                                self.variationsPrice.push(currentObjVariation)
                            }
                            currentObjVariation = { key: checkVariationCode, variations: [], firstVariation: variation }
                        }
                        currentObjVariation.variations.push(variation)
                    })

                    if (currentObjVariation.variations.length > 0) {
                        self.variationsPrice.push(currentObjVariation)
                    }

                }
                ).catch(error => {
                    console.log("error")
                    console.log(error)
                }).finally(() => {
                    self.loadingResult = false
                })

            },
            loadCountries: function () {
                let self = this
                if (this.groupCountries.length <= 0) {
                    setTimeout(() => { self.loadCountries() }, 1000);
                    return;
                }
                this.countriesChk = []
                this.lcountries = []
                let countainIds = []
                this.groupCountries.forEach(gCountries => {
                    if (this.gCountriesChk.length > 0 && this.gCountriesChk.indexOf(gCountries.id) < 0) {
                        return;
                    }
                    gCountries.countries.forEach(country => {
                        if (countainIds.indexOf(country.id) < 0) {
                            countainIds.push(country.id)
                            this.lcountries.push(country)
                        }
                    })
                })

            },
            searchUpdated: function (objSearch) {

                if (this.loadingResult) {
                    return;
                }
                this.loadingResult = true;
                let self = this
                this.variationsPrice = []
                this.updatedProducts = []
                this.varDetailShow = {}
                let urlLoad = this.$ipService + '/api/products/updatesearch'
                axios.post(urlLoad, objSearch).then(response => {
                    let updatedProducts = response.data
                    updatedProducts.sort((a, b) => {
                        if (a.groupProductCode != b.groupProductCode) {
                            return a.groupProductCode.localeCompare(b.groupProductCode)
                        }
                        if (a.productCode != b.productCode) {
                            return a.productCode.localeCompare(b.productCode)
                        }
                        return a.adapterId - b.adapterId
                    });

                    updatedProducts.forEach(variation => {
                        variation.website = self.getWebSite(variation.adapterId)
                        variation.productId = variation.id
                        variation.productCode = variation.code
                        self.updatedProducts.push(variation)
                    })

                }
                ).catch(error => {
                    console.log("error")
                    console.log(error)
                }).finally(() => {
                    self.loadingResult = false
                })

            },
            searchProducts: function () {


                let self = this
                let objSearch = { dateSearch: self.dateFrom, typeSearch: self.variationType, userId: self.currentUser.id , adapters: self.websitesChk, gCountries: self.gCountriesChk, countries: self.countriesChk}

                if (objSearch.typeSearch == "updatedprod") {
                    return self.searchUpdated(objSearch)
                }

                if (this.loadingResult) {
                    return;
                }
                this.loadingResult = true;
                this.variationsPrice = []
                this.updatedProducts = []
                this.varDetailShow = {}
                let urlLoad = this.$ipService + '/api/products/pricesearch'
                axios.post(urlLoad, objSearch).then(response => {
                    let variationsPrice = response.data
                    variationsPrice.sort((a, b) => {
                        if (a.groupProductCode != b.groupProductCode) {
                            return a.groupProductCode.localeCompare(b.groupProductCode)
                        }
                        if (a.productCode != b.productCode) {
                            return a.productCode.localeCompare(b.productCode)
                        }
                        if (a.adapterId != b.adapterId) {
                            return a.adapterId - b.adapterId
                        }
                        return a.dateDeparture.localeCompare(b.dateDeparture)
                    });
                    let currentObjVariation = {
                        key: "", variations: [], firstVariation: {}
                    }
                    variationsPrice.forEach(variation => {
                        variation.website = self.getWebSite(variation.adapterId)
                        let listCountries = [];
                        let lCountries = variation.productCountries.split(";")
                        lCountries.forEach(countyCode => {
                            let searchCountry = self.getCountry(countyCode)
                            if (searchCountry != null) {
                                listCountries.push(searchCountry)
                            }
                        })
                        variation.productCountries = listCountries
                        let checkVariationCode = variation.groupProductCode + "_" + variation.productCode;
                        if (currentObjVariation.key != checkVariationCode) {
                            if (currentObjVariation.variations.length > 0) {
                                self.variationsPrice.push(currentObjVariation)
                            }
                            currentObjVariation = { key: checkVariationCode, variations: [], firstVariation: variation }
                        }
                        currentObjVariation.variations.push(variation)
                    })

                    if (currentObjVariation.variations.length > 0) {
                        self.variationsPrice.push(currentObjVariation)
                    }

                }
                ).catch(error => {
                    console.log("error")
                    console.log(error)
                }).finally(() => {
                    self.loadingResult = false
                })
            },
            loadProducts: function (typeSearch, nextPart) {
                let self = this
                let dateSearch = moment(this.currentUser.lastLogin.substr(0, 10)).add(1, 'days').format('YYYY-MM-DD')                
                axios.get(this.$ipService + '/api/products/' + typeSearch + '/' + dateSearch + "/" + this.currentUser.id
                ).then(function (response) {
                    if (typeSearch == 'pricedown') {
                        self.pricedown = response.data.filter(price => self.getWebSite(price.adapterId).enabled );
                        self.totalpricedown = 0;
                        response.data.forEach(price => {
                            self.totalpricedown += price.totalVariation
                        })
                    }
                    if (typeSearch == 'priceup') {
                        self.priceup = response.data.filter(price => self.getWebSite(price.adapterId).enabled );
                        self.totalpriceup = 0;
                        response.data.forEach(price => {
                            self.totalpriceup += price.totalVariation
                        })
                    }
                    if (typeSearch == 'promo') {
                        self.promo = response.data.filter(price => self.getWebSite(price.adapterId).enabled );
                        self.totalpromo = 0;
                        response.data.forEach(price => {
                            self.totalpromo += price.totalVariation
                        })
                    }
                    if (typeSearch == 'promoup') {
                        self.promoup = response.data.filter(price => self.getWebSite(price.adapterId).enabled );
                        self.totalpromoup = 0;
                        response.data.forEach(price => {
                            self.totalpromoup += price.totalVariation
                        })
                    }
                    if (typeSearch == 'garanti') {
                        self.garanti = response.data.filter(price => self.getWebSite(price.adapterId).enabled );
                        self.totalgaranti = 0;
                        response.data.forEach(price => {
                            self.totalgaranti += price.totalVariation
                        })
                    }
                    if (typeSearch == 'close') {
                        self.close = response.data.filter(price => self.getWebSite(price.adapterId).enabled );
                        self.totalclose = 0;
                        response.data.forEach(price => {
                            self.totalclose += price.totalVariation
                        })
                    }
                    if (typeSearch == 'delete') {
                        self.deletedate = response.data.filter(price => self.getWebSite(price.adapterId).enabled );
                        self.totaldeletedate = 0;
                        response.data.forEach(price => {
                            self.totaldeletedate += price.totalVariation
                        })
                    }
                    if (typeSearch == 'new') {
                        self.newdate = response.data.filter(price => self.getWebSite(price.adapterId).enabled );
                        self.totalnewdate = 0;
                        response.data.forEach(price => {
                            self.totalnewdate += price.totalVariation
                        })
                    }
                    if (typeSearch == 'newprod') {
                        self.newprod = response.data.filter(price => self.getWebSite(price.adapterId).enabled );
                        self.totalnewprod = 0;
                        response.data.forEach(price => {
                            self.totalnewprod += price.totalVariation
                        })
                    }
                    if (typeSearch == 'oldprod') {
                        self.oldprod = response.data.filter(price => self.getWebSite(price.adapterId).enabled );
                        self.totaloldprod = 0;
                        response.data.forEach(price => {
                            self.totaloldprod += price.totalVariation
                        })
                    }
                    if (typeSearch == 'updatedprod') {
                        self.updatedprod = response.data.filter(price => self.getWebSite(price.adapterId).enabled );
                        self.totalupdatedprod = 0;
                        response.data.forEach(price => {
                            self.totalupdatedprod += price.totalVariation
                        })
                    }
                }).catch(function (error) {
                    console.log(error)
                }).finally(() => {
                    nextPart()
                })
            }
        },
        mounted: function () {
            let self = this
            this.onloading = true 
            this.loadProducts('pricedown',
                function () {
                    self.loadProducts('priceup',
                        function () {
                            self.loadProducts('promo',
                                function () {
                                    self.loadProducts('promoup',
                                        function () {
                                            self.loadProducts('garanti',
                                                function () {
                                                    self.loadProducts('close',
                                                        function () {
                                                            self.loadProducts('delete',
                                                                function () {
                                                                    self.loadProducts('new',
                                                                        function () {
                                                                            self.loadProducts('newprod',
                                                                                function () {
                                                                                    self.loadProducts('oldprod',
                                                                                        function () {
                                                                                            self.loadProducts('updatedprod',
                                                                                                function () {
                                                                                                    self.onloading = false 
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                    )
                                                                                }
                                                                            )
                                                                        }
                                                                    )
                                                                }
                                                            )
                                                        }
                                                    )
                                                }
                                            )
                                        }
                                    )
                                }
                            )
                        }
                    )
                }
            )
            /**
            this.loadProducts('promo')
            this.loadProducts('promoup')
            this.loadProducts('garanti')
            this.loadProducts('close')
            this.loadProducts('delete')
            this.loadProducts('new')
            this.loadProducts('newprod')
            this.loadProducts('oldprod')
            this.loadProducts('updatedprod')
            **/
            this.dateFrom = this.currentUser.lastLogin.substr(0, 10)
            this.websitesChk = []
            this.webSites.forEach(webSites => {
                this.websitesChk.push(webSites.id)
            })
            this.gCountriesChk = []
            this.loadCountries()
        }
    };
</script>

<style scoped>
    #div-variations {
    }

    #div-variations div {
    }

    #variationFilterArea {
        padding: 10px 20px;
    }

    #content-variations {
        float: left;
        margin: 10px;
        width: 80%
    }

    #div-left {
        border: 1px solid black;
        min-height: 200px;
        width: 10%;
        display: block;
        margin: 10px;
        float: left;
        background-color: #FFF;
    }

    .datePart {
        background-color: lightgrey !important;
        border: none !important;
        border-bottom: 1px solid black !important;
        padding: 10px 5px;
        font-weight: bolder;
    }

    #div-left h3 {
        font-size: 12px;
        font-weight: bold;
        padding-left: 5px;
        margin-bottom: 0px;
        cursor: pointer;
        padding: 10px 5px
    }

    #div-left ul {
        list-style-type: none;
        padding-left: 10px;
    }

    #div-left li {
        cursor: pointer;
    }

        #div-left li:hover {
            text-decoration: underline;
        }



    label {
        text-align: right !important;
        font-weight: bolder;
    }

    .div-checkable {
        margin-bottom: 5px;
        border-color: #AAAAAA;
        border-style: solid;
        border-width: 1px;
        float: left;
        min-width: 100px;
        width: 80%;
        height: 100px;
        margin-left: 0;
        overflow-y: auto;
        font-size: 1em;
        vertical-align: middle;
    }

    tr.odd {
        background-color: #d9d9d9;
    }

    tr.odd1 {
        background-color: #bdd9eb;
    }

    tr.ojj1 {
        background-color: #dff2ff;
    }

    .pointer {
        cursor: pointer;
    }

    .pointer:hover {
        text-decoration: underline;
    }

    .lds-roller {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }

    .lds-roller div {
        animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        transform-origin: 40px 40px;
    }

    .lds-roller div:after {
        content: " ";
        display: block;
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #cef;
        margin: -4px 0 0 -4px;
    }

    .lds-roller div:nth-child(1) {
        animation-delay: -0.036s;
    }

    .lds-roller div:nth-child(1):after {
        top: 63px;
        left: 63px;
    }

    .lds-roller div:nth-child(2) {
        animation-delay: -0.072s;
    }

    .lds-roller div:nth-child(2):after {
        top: 68px;
        left: 56px;
    }

    .lds-roller div:nth-child(3) {
        animation-delay: -0.108s;
    }

    .lds-roller div:nth-child(3):after {
        top: 71px;
        left: 48px;
    }

    .lds-roller div:nth-child(4) {
        animation-delay: -0.144s;
    }

    .lds-roller div:nth-child(4):after {
        top: 72px;
        left: 40px;
    }

    .lds-roller div:nth-child(5) {
        animation-delay: -0.18s;
    }

    .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
    }

    .lds-roller div:nth-child(6) {
        animation-delay: -0.216s;
    }

    .lds-roller div:nth-child(6):after {
        top: 68px;
        left: 24px;
    }

    .lds-roller div:nth-child(7) {
        animation-delay: -0.252s;
    }

    .lds-roller div:nth-child(7):after {
        top: 63px;
        left: 17px;
    }

    .lds-roller div:nth-child(8) {
        animation-delay: -0.288s;
    }

    .lds-roller div:nth-child(8):after {
        top: 56px;
        left: 12px;
    }

    .product-title {
        cursor: pointer;
    }

    .modifval{
        background-image: url("/images/modif.png");
        background-repeat: no-repeat;
        background-position-x: right;
    }

    @keyframes lds-roller {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
</style>
