<template>
    <b-card title="Modification de pays des sites">
        <b-form>
            <b-form-group id="input-group-1"
                          label="Nom :"
                          label-for="input-1">
                <b-form-input id="input-1"
                              type="text"
                              placeholder="Nom"
                              v-model="countryInsert.name"
                              @change="searchCInsertByName($event)"
                              required></b-form-input>
              <div v-if="formError" class="text-danger">
                Le nom du pays existe déjà
              </div>
            </b-form-group>
            <b-form-group id="input-group-3" label="Filtre :" label-for="input-3">
                <b-form-select id="input-3"
                               :options="selectOptions"
                               v-model="selectedOption" @change="setCountryList(true)"></b-form-select>
            </b-form-group>

            <b-form-group id="input-group-3" label="Pays :" label-for="input-3">
              <b-input-group size="sm">
                <b-form-input id="filter-input"
                              v-model="filterValue"
                              placeholder="Nom du Pays" @change="filter = filterValue"></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!filterValue" @click="filter = filterValue">Filtrer</b-button>
                  <b-button :disabled="!filter" @click="filter = ''; filterValue = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
              <b-pagination v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            aria-controls="countriesList" ></b-pagination>
              <b-table :items="countriesList" :fields="countriesFields" head-variant="light" sticky-header="600px" :per-page="perPage"
                       :current-page="currentPage"
                       :filter="filter"
                       :filter-included-fields="filterOn"
                       :sort-by.sync="sortBy"
                       :busy="refreshTable"
                       tbody-tr-class="trclass"  @filtered="onFiltered"
              >
                <template #cell(id)="data">
                  <b-icon-check-circle v-if="selectedCountriesItems[data.value] == 1" class="icon-span" @click="selectedCountriesItemClick(data.value);selectedCountriesUpdate()" />
                  <b-icon-circle v-if="selectedCountriesItems[data.value] != 1" class="icon-span" @click="selectedCountriesItemClick(data.value);selectedCountriesUpdate()" />
                </template>
                <template #cell(name)="data">
                  <span class="text-capitalize">{{ data.value }}</span>
                </template>
              </b-table>
            </b-form-group>
            <b-button type="button" :disabled="formError" variant="primary" @click="postform">Submit</b-button>
            <b-button type="button" variant="warning" @click="resetform">Retour</b-button>
        </b-form>
    </b-card>
</template>

<script>
    import { mapState } from "vuex"
    import axios from 'axios'


    export default {
        name: 'EditCountryInsert',
        components: {
        },
        data() {
            return {
                formError: false,
                refreshTable: true,
                countriesList: [],
                selectedCountriesItems: {},
                countryCleans: [],
                filterValue: null,
                filter: null,
                sortBy: 'name',
                filterOn: ['name'],
                perPage: 8,
                currentPage: 1,
                countRows: -1,
                countriesFields: [
                  {
                    key: 'id',
                    label: 'Selection',
                    thStyle: { width: "8%" },
                  },
                  {
                    key: 'code',
                    label: 'Code',
                    thStyle: { width: "8%" },
                  },
                  {
                    key: 'name',
                    label: 'Pays',
                    thStyle: { width: "62%" },
                  }
                ],
                selectedCountries: [],
                listOptions: [],
                selectedOption: 'group',
                countryInsert: {},
                selectOptions: [
                    { value: "all", text: "Tous les pays" },
                    { value: "empty", text: "Pays non affectés" },
                    { value: "group", text: "Pays du groupe uniquement" },
                    { value: "groups", text: "Pays déjà affectés uniquement" }
                ],
                action: "list",
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return ''
                value = value.toString().toLowerCase()

                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            upper: function (value) {
                if (!value) return ''
                return value.toString().toUpperCase()
            },
            ln2br: function (value) {
                if (!value) return ''
                return value.toString().replace("\n", "<br>")
            },
            countryToLine: function (value) {
                if (value.length == 0) return ''
                let arrName = []
                value.forEach(vA => { arrName.push(vA.name) });
                arrName.sort((a, b) => {
                    return a.localeCompare(b);
                })
                return arrName.join(", ")
            },
        },
        props: {
            editId: Number
        },
        event: ['reset', 'save'],
        methods: {
            selectedCountriesItemClick : function (key) {
              if (this.selectedCountriesItems[key] !== undefined) {
                this.selectedCountriesItems[key] = this.selectedCountriesItems[key] == 0 ? 1 : 0;
                return true
              }
              return false
            },
            selectedCountriesAdd : function (key) {
              if (this.selectedCountriesItems[key] === undefined) {
                this.selectedCountriesItems[key] = 0;
                return true
              }
              return false
            },
            selectedCountriesUpdate : function () {
              this.selectedCountries = this.selectedCountriesCpt();
              this.setCountryList(false)
            },
            selectedCountriesReset : function() {
              this.selectedCountries = this.selectedCountriesCpt();
              this.selectedCountriesItems = {}
              this.selectedCountries.forEach((key) => {
                this.selectedCountriesItems[key] = 1;
              });
              return this.selectedCountries;
            },
            selectedCountriesCpt : function () {
              return Object.keys(this.selectedCountriesItems).map((key) =>
                  {
                    if (this.selectedCountriesItems[key] )
                    {
                      return key;
                    }
                    return null;
                  }
              ).filter((val) => (val !== undefined && val !== null));
            },
            resetform: function () {
                this.$emit('reset')
            },
            postform: function () {
                let objPost = {
                    id: this.countryInsert.id,
                    name: this.countryInsert.name,
                    countries: this.selectedCountriesCpt()
                }
                let self = this
                axios.post(this.$ipService + '/api/CountryInserts/' + objPost.id, objPost
                ).then(function () {
                    self.$store.dispatch('loadCountryInserts')
                    self.$store.dispatch('loadGroupcountries')
                    self.$store.dispatch('loadCountries')
                    self.$store.dispatch('loadCountriesActives')
                    self.$store.dispatch('loadGrouptypes')
                    self.$store.dispatch('loadGrouplevels')
                    self.$store.dispatch('loadWebsites')
                    self.$store.dispatch('loadGroupdurations')
                    self.$store.dispatch('loadGroupproducts')
                    self.$emit('reset')
                }).catch(function (error) {
                    console.log(error)
                }).finally(() => {

                })
            },
            getEmptyList: function () {
              let result = []
              this.selectedCountries = this.selectedCountriesReset();

              this.countries.forEach(ctry => {
                  if (ctry?.countryInserts === undefined) {
                    this.selectedCountriesAdd(ctry.id)
                    return
                  }
                  if (ctry.countryInserts.length == 0) {
                      this.selectedCountriesAdd(ctry.id)
                  }
                })
                this.selectedCountries.forEach(
                    (code) => {
                      if (this.countriesByCode[code] === undefined) {
                        return
                      }
                      let ctry = { id: code,  code: code, name: this.countriesByCode[code].name }
                      this.selectedCountriesAdd(ctry.id)
                    }
                )
                result =  Object.keys(this.selectedCountriesItems).map((code) => {
                  return { id: code,  code: code, name: this.countriesByCode[code].name }
                })
                return result
            },
            getAllList: function () {
                this.selectedCountries = this.selectedCountriesReset();
                let result = [];
                this.countries.forEach(ctry => {
                    this.selectedCountriesAdd(ctry.id)
                    result.push({ id: ctry.id,  code: ctry.id, name: ctry.name })
                })
                result =  Object.keys(this.selectedCountriesItems).map((code) => {
                  return { id: code,  code: code, name: this.countriesByCode[code].name }
                })
                return result;
            },
            getGroupList: function () {
                this.selectedCountries = this.selectedCountriesReset();
                let result = []
                if (this.countryInsert?.countryCodes === undefined) {
                  return []
                }
                this.selectedCountries.forEach(
                    (code) => {
                      if (this.countriesByCode[code] === undefined) {
                        return
                      }
                      let ctry = { id: code,  code: code, name: this.countriesByCode[code].name }
                      this.selectedCountriesAdd(ctry.id)
                      result.push(ctry);
                    }
                )
                result =  Object.keys(this.selectedCountriesItems).map((code) => {
                  return { id: code,  code: code, name: this.countriesByCode[code].name }
                })
                return result
            },
            getGroupsList: function () {
                this.selectedCountries = this.selectedCountriesReset();
                let result = []
                this.countries.forEach(ctry => {
                    if (ctry?.countryInserts === undefined) {
                      return
                    }
                    if (ctry.countryInserts.length > 0) {
                        this.selectedCountriesAdd(ctry.id)
                        result.push({ id: ctry.id,  code: ctry.id, name: ctry.name })
                    }
                })

                result =  Object.keys(this.selectedCountriesItems).map((code) => {
                  return { id: code,  code: code, name: this.countriesByCode[code].name }
                })
                return result
            },
            onFiltered: function(filteredItems) {
              // Trigger pagination to update the number of buttons/pages due to filtering
              if (this.countriesList == undefined || this.countriesList == null) {
                this.countRows = -1
                this.currentPage = 1
                return
              }
              this.countRows = filteredItems.length
              this.currentPage = 1
            },
            setCountryList: function (resetValues = true){
              this.refreshTable = true
              if (resetValues) {
                this.currentPage = 1
                this.filterValue = ''
                this.filter = ''
                this.countRows = -1
              }
              this.countriesList = [];
              if (this.selectedOption == "all") {
                this.countriesList = this.getAllList()
              } else if (this.selectedOption == "empty") {
                this.countriesList = this.getEmptyList()
              } else if (this.selectedOption == "group") {
                this.countriesList = this.getGroupList()
              } else {
                this.countriesList = this.getGroupsList()
              }
              this.countRows = this.countriesList.length
              this.refreshTable = false
            },
            searchCInsertByName: function (value) {

              let self = this
              this.formError = true
              axios.get(this.$ipService + '/api/CountryInserts/Search/' + value + '?date=' + new Date().getTime()
              ).then(function (response) {
                if (response.data.length <= 0) {
                  self.formError = false
                }
                let hasError = false
                for (let i = 0; i < response.data.length; i++) {
                  if (response.data[i].id != self.countryInsert.id) {
                    hasError = true
                    break
                  }
                }
                self.formError = hasError
              });
            }

        },
        computed: {
            totalRows: function () {
              if (this.countRows > 0) {
                return this.countRows
              }
              return this.countriesList.length
            },
            ...mapState(["countryInserts", "countries", "currentUser", "countriesByCode"])
        },
        mounted: function () {
            let self=this
            axios.get(this.$ipService + '/api/CountryInserts/' + this.editId + '?date=' + new Date().getTime()
            ).then(function (response) {
                self.countryInsert = response.data
            }).catch(function (error) {
                console.log(error);
            }).finally(() => {
              self.selectedCountriesItems = {}
              if (self.countryInsert?.countryCodes === undefined) {
                self.selectedCountriesItems = {}
                self.selectedCountries = []
                return
              }
              self.countryInsert.countryCodes.forEach((code) => {
                self.selectedCountriesItems[code] = 1;
              })
              self.selectedCountries = self.selectedCountriesCpt();
              this.countriesList = this.getGroupList()
              this.setCountryList();
            })


        }
    };
</script>

<style scoped>
    .icon-span {
        margin: 5px;
    }

    .clickable {
        cursor: pointer
    }
</style>
