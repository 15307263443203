<template>
    <b-card title="Edition des utilisateurs" >
        <b-form title="Information de connexion">
            <b-form-group id="input-group-email"
                          label="Email :"
                          label-for="user-email">
                <b-form-input id="user-email"
                              type="email"
                              placeholder="Email"
                              v-model="postuser.email"
                              required></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-password"
                          label="Password :"
                          label-for="user-password">
                <b-form-input id="user-password"
                              type="password"
                              placeholder="*****"
                              v-model="postuser.password"
                              required></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-type"
                          label="Type :"
                          label-for="input-type">
                <b-form-select id="input-type"
                               :options="typeUserOptions"
                               v-model="postuser.userTypeId"></b-form-select>
            </b-form-group>
            <b-form-group id="input-group-firstname"
                          label="Prénom :"
                          label-for="user-firstname">
                <b-form-input id="user-email"
                              type="text"
                              placeholder="Prénom"
                              v-model="postuser.firstName"
                              required></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-lastname"
                          label="Nom :"
                          label-for="user-lastname">
                <b-form-input id="user-lastname"
                              type="text"
                              placeholder="Nom"
                              v-model="postuser.lastName"
                              required></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-function"
                          label="Fonction :"
                          label-for="user-function">
                <b-form-input id="user-function"
                              type="text"
                              placeholder="Fonction"
                              v-model="postuser.function"
                              required></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-role"
                          label="Role :"
                          label-for="user-role">
                <b-form-input id="user-role"
                              type="text"
                              placeholder="Role"
                              v-model="postuser.role"
                              required></b-form-input>
            </b-form-group>

            <b-button type="button" variant="primary" @click="postform">Submit</b-button>
            <b-button type="button" variant="warning" @click="resetform">Retour</b-button>
        </b-form>
    </b-card>
</template>

<script>
    import { mapState } from "vuex"
    import axios from 'axios'


    export default {
        name: 'EditUser',
        components: {
        },
        data() {
            return {
                postuser: {},
                typeUserOptions: [
                    { value: "1", text: "Administrateur" },
                    { value: "2", text: "Utilisateur" }
                ],
            }
        },
        filters: {
        },
        props: {
            editId: Number
        },
        event: ['reset', 'save'],
        methods: {
            resetform: function () {
                this.$emit('reset')
            },
            postform: function () {
                let self = this
                axios.post(this.$ipService + '/api/Users/' + this.postuser.id, this.postuser
                ).then(function () {
                }).catch(function (error) {
                    console.log(error)
                }).finally(() => {
                    self.$emit('updated')
                    self.$emit('reset')
                })

                
            },
        },
        computed: {
            ...mapState(["currentUser"])
        },
        mounted: function () {
            let self=this
            axios.get(this.$ipService + '/api/Users/' + this.editId
            ).then(function (response) {
                self.postuser = {
                    id: response.data.id,
                    email: response.data.email,
                    password: null,
                    userTypeId: response.data.userTypeId,
                    profileId: response.data.profile.id,
                    lastName: response.data.profile.lastName,
                    firstName: response.data.profile.firstName,
                    civility: response.data.profile.civility,
                    birthDate: response.data.profile.birthDate,
                    adress1: response.data.profile.adress1,
                    adress2: response.data.profile.adress2,
                    zipCode: response.data.profile.zipCode,
                    city: response.data.profile.city,
                    province: response.data.profile.province,
                    country: response.data.profile.country,
                    status: response.data.profile.status,
                    function: response.data.profile.function,
                    role: response.data.profile.role 
                }
            }).catch(function (error) {
                console.log(error);
            })

            
        }
    };
</script>

<style scoped>
    .icon-span {
        margin: 5px;
    }

    .clickable {
        cursor: pointer
    }
</style>
